

/* Mobile & Tablet */
@media screen and (max-width : 1023px) {
  .view{
    .mobile{
      .TaiKhoanFabysa-ConTaiNer{
        font-size: 3.5vw;
        margin-bottom: 30vw;
        .quayLai-tieuDe{
          width: 100vw;
          display: flex;
          font-size: 3.5vw;
          background-color: #fff;
          border-bottom: #d0d4dd 1px solid;
          position: fixed;
      
          top: 0px;
            z-index: 1;
          a{
              text-decoration: none;
          }
          .quayLai{
              color: #04aa6d;
              width: 25vw;
              text-align: left;
              line-height: 9vw;
              padding-left: 2vw;
             
          }
            .donHang{
                font-size: 3.9vw;
                font-weight: 600;
                color: red;
                text-transform: uppercase;
                width: 39vw;
                line-height: 9vw;
                padding-left: 10vw;
         
      
            }
          .tien-container{
            line-height: 9vw;
            text-align: right;
              padding-right: 2vw;
            width: 36vw;
            font-size: 3.5vw;
            line-height: 9vw;
            // color: red;
            span{
              font-weight: 600;
              line-height: 6vw;
              text-align: center;
              color: #04aa6d;
              text-decoration-line:overline;
              text-decoration-color:red;
              text-decoration-style:double;
            }
          }
        }
        .thongTinTK{
          margin: 12vw auto auto 3vw;
          display: flex;
          gap: 2vw;
          .avatar{


          }
          .ten-soDu{
            margin-top: 7vw;
            .ten{
              font-size: 5vw;
              font-weight: 600;
              line-height: 8vw;
              text-align: left;

            }
            .soDu{
              line-height: 5vw;
              text-align: left;
              font-weight: 600;

            }

          }

        }
        .menu{
          margin: 5vw 2.5vw;
          display: flex;
          gap: 2.5vw;
          .icon-phim{
            width: 30vw;
            line-height: 8vw;
            background-color: #04aa6d;
            color: #fff;
            border-radius: 4vw;
            text-align: center;
            font-weight: 600;
          }
          .icon-phim2{
            width: 30vw;
            line-height: 8vw;
            background-color: red;
            color: #fff;
            border-radius: 4vw;
            text-align: center;
            font-weight: 600;
          }
          

        }
        .chiTietMenu{
          .gioiThieu{
            .tieuDe{
              font-weight: 600;
              text-align: left;
              padding-left: 2vw;
              line-height: 8vw;

            }
            .noiDung{
              text-align: left;
              padding-left: 2vw;
              line-height: 5vw;
            }
            .kiHieu-container{
              margin-top: 20vw;
              .kiHieu{
                font-size: 30vw;
                color: #04aa6d;
                text-decoration-line:overline;
                text-decoration-color:red;
                text-decoration-style:double;
              }
            }
          }
          .nap{
            .tieuDe{
              font-weight: 600;
              text-align: left;
              padding-left: 2vw;
              line-height: 8vw;
            }
            .huongDan{
              text-align: left;
              padding-left: 2vw;
              line-height: 5vw;
            }
            .taikhoan{
              display: flex;
              margin: 5vw 2vw;
              .ttTaiKhoan{
                width: 40vw;
                .nganHang{
                  text-align: left;
                  line-height: 6vw;
                  font-weight: 600;
    
                }
                .tenNganHang{
                  margin-bottom: 1vw;
                  padding-left: 2vw;
                  line-height: 8vw;
                  border: solid 1px gray;
                  border-radius: 4vw;
    
                }
    
              }
              .qr-container{
                width: 56vw;
    
              }
            }
            .fabysa2-soLuong{
              margin: 5vw 2vw 3vw;
              .fabysa2{
                text-align: left;
                font-weight: 600;
                line-height: 8vw;
               
    
              }
              .soLuong2{
                width: 96vw;
                border: solid 1px gray;
                border-radius: 4vw;
                line-height: 8vw;
    
    
              }
            }
            .ghiChu{
              text-align: left;
              padding-left: 2vw;
              font-style:italic;
              line-height: 5vw;
            }
            .daCK{
              margin: 10vw 2vw 5vw;
              width: 96vw;
              background-color: #04aa6d;
              font-weight: 600;
              color: #fff;
              line-height: 8vw;
              border-radius: 4vw;
            }
            .choXN{
              margin: 10vw 2vw 5vw;
              width: 96vw;
              background-color: red;
              font-weight: 600;
              color: #fff;
              line-height: 8vw;
              border-radius: 4vw;
            }
           

          }
          .rut{
            .tieuDe{
              font-weight: 600;
              text-align: left;
              padding-left: 2vw;
            }
            .huongDan{
              text-align: left;
              padding-left: 2vw;
            }

          }
          .lichSu{
            // background-color: #e5e5e5;
            // padding: 2vw;
            .tieuDe{
              font-weight: 600;
              text-align: left;
              padding-left: 2vw;
            }
            .allGiaoDich{

              .giaoDich{
                margin: 2vw;
                border-radius: 3vw;
                border: solid 1px gray;
                .noiDung{
                  line-height: 6vw;
                  font-weight: 600;
                  text-align: left;
                  padding-left: 2vw;
                  width: 92vw;
                }
                .tgian-soTien-thanhCong{
                  display: flex;
                  line-height: 6vw;
                  .tgian{
                    width: 32vw;
                    text-align: left;
                    padding-left: 2vw;
                  }
         
                  .thanhCong{
                    width: 32vw;
                
                  }
                  .soTien{
                    width: 32vw;
                    text-align: right;
                    padding-right: 2vw;
                   
                  }

                }

              }
            }

          }
        }
       
        
      }
    }
    .pc{
      display: none;
    }
  }
  
}

/* PC*/
@media screen and (min-width : 1024px) {
  .view{
    .mobile{
     display: none;
    }
    .pc{
      .TaiKhoanFabysa-ConTaiNer{
    
      }
    }
  }
}
@media screen and (max-width : 1023px) {
 .view{
    .mobile{
      .ThemeShop-ConTaiNer{
        // padding-bottom: 30vw;
        .kiHieu-nhomSanPham2-menu{
          background-color: #04aa6d;
          display: flex;
          .nhomSanPham2{
            width: 90vw;
            color: #fff;
            line-height: 10vw;
            font-size: 3.9vw;
            font-weight: 800;
            text-align: left;
            text-transform: uppercase;
            margin-left: 2vw;
          }  
          .menu{
            width: 8vw;
            line-height: 8vw;
            color: #fff;
            margin:1vw 1vw 1vw 2vw;
            font-weight: 600;
            font-size: 5vw;

          }
        }
        .sanPham-container{
            display: grid;
            grid-template-columns: 1fr 1fr;
            padding: 2vw;
            gap:2vw;
            background-color: #e5e5e5;
            .sanPham {
              width: 47vw;
                background-color: #fff;
                .anhSanPham {
                    width: 47vw;
                    height: 62vw;
                    object-fit: cover;
                }
         
                  .tenSP{
                  width: 46vw;
                  text-align: left;
                  font-size: 3.5vw;
                  font-weight: 600;
                  margin: auto 1vw;
                  line-height: 5vw;
                  overflow: hidden;

                  }
                  .giaCu{
                    width: 47vw;
                    font-size: 3.5vw;
                    font-weight: 600;
                    text-align: left;
                    margin-left: 1vw;
                    line-height: 6vw;
                    text-decoration: line-through;
                  }
                  .giaBan-phanTram{
                  width: 47vw;
                  display: flex;
                  font-size: 3.9vw;
                  font-weight: 600;
                  .giaBan{
                    width: 30vw;
                    text-align: left;
                    margin-left: 1vw;
                    line-height: 6vw;
                    color: red;

                  }
                  .phanTram{
                    width: 15vw;
                    text-align: right;
                    line-height: 6vw;
                    margin-right: 1vw;
                    overflow: hidden


                  }
                  }
                  .themGH{
                    line-height: 8vw;
                    width: 41vw;
                    margin: 3vw;
                    font-size: 3.5vw;
                    font-weight: 600;
                    background-color: #e5e5e5;
                    border-radius: 4vw;
                    text-transform: uppercase;
                  } 
                  .daThem{
                    line-height: 8vw;
                    width: 41vw;
                    margin: 3vw;
                    color: #fff;
                    font-size: 3.5vw;
                    font-weight: 600;
                    background-color: red;
                    border-radius: 4vw;
                    text-transform: uppercase;
                  }
            }
          
         
        }
       
   
      
        
      }
    }
    .pc{
      display: none;
    }
  }
  
}
 @media screen and (min-width : 1024px) {
  .view{
     .mobile{
       display: none;
     }
     .pc{
       .ThemeShop-ConTaiNer{
     }
   }
   
 }
 }
 
@media screen and (max-width : 1023px) {
 .view{
    .mobile{
      .DinhDangSo-ConTaiNer{
      a{
        text-decoration: none;
        color: black;

      }
        .vnd{
          text-decoration-line:underline;
          color: red;
        }
        .fabysa{
          color: #04aa6d;
          text-decoration-line:overline;
          text-decoration-color:red;
          text-decoration-style:double;
        }
      }
    }
    .pc{
      display: none;
    }
  }
  
}
 @media screen and (min-width : 1024px) {
  .view{
     .mobile{
       display: none;
     }
     .pc{
       .DinhDangSo-ConTaiNer{
     }
   }
   
 }
 }
 

/* Mobile & Tablet */
@media screen and (max-width : 1023px) {
  .chiTietDonHang-Container{
    a{text-decoration: none};
    font-size: 3vw;
    margin: 0vw auto 30vw;
    .quayLai-tieuDe{
      display: flex;
      font-size: 3.5vw;
      background-color: #fff;
      border-bottom: #d0d4dd 1px solid;
      position: fixed;
      top: 0px;
        z-index: 1;
      a{
          text-decoration: none;
      }
      .quayLai{
          color: #04aa6d;
          width: 25vw;
          text-align: left;
          line-height: 9vw;
          padding-left: 2vw;
         
      }
      .tieuDe{
        font-size: 3.9vw;
          font-weight: 600;
          color: red;
          text-align-last: center;
          width: 66vw;
          line-height: 9vw;
          text-transform: uppercase;
          padding-right: 16vw;
         
      }
      .inHoaDon{
        width: 9vw;
        text-align: center;
        line-height: 9vw;
        font-size: 5vw;
       
      }
  }
  .sanPham-container{
      margin-top:12vw;

    .allSanPham{
     .sanPham{
      margin-bottom: 1vw;
      .tenSanPham{
        width: 90vw;
        text-align: left;
          font-size: 3.5vw;
          font-weight: 600;
          margin-bottom: 2vw;
          color: red;
          text-transform: uppercase;
          line-height: 3vw;
          margin: auto 2vw;
  
       }
    .dacDiem-themGioHang{
    display: flex;
    margin: 2vw auto;
    font-size: 3vw;
    .anhSp-tenSp{
      width: 20vw;
      .tenSp{
        font-weight: 500;
        word-wrap: break-word;
        line-height: 5vw;
      }
      .gioPhut{
        color: red;
        line-height: 5vw;
        line-height: 5vw;
      }
    }
    .giaSanPham{
      width: 30vw;
      .giaKM{
        font-weight: 500;
        color: red;
        line-height: 5vw;
      }
      .giaNY-giamGia{
        display: flex;
        gap: 1vw;
        justify-content: center;
        align-items: center;
        .giaNY{
          text-decoration: line-through;
          line-height: 5vw;

        }
        .giamGia{
          font-size: 2vw;
          line-height: 5vw;

        }
      }
     
    }
    .soLuong-SL{
      width: 15vw;
      justify-content: center;
        align-items: center;
      
      .soLuong{
        line-height: 5vw;
      }
      .SL{
        width: 90%;
        text-align: center;
        font-weight: 600;
        border-radius: 1vw;
        height: 5vw;

      }

    }
    .thanhTien-TT{
      width: 26vw;
      .thanhTien{
        justify-content: center;
        align-items: center;
        line-height: 5vw;
      }
      .TT{
        text-align: center;
        font-weight: 600;
        justify-content: center;
        align-items: center;
        line-height: 5vw;
      }
    }
    .daXong{
      width: 9vw;
      font-size: 5vw;
      line-height: 10vw;
    }
    .chuaXong{
      width: 9vw;
      font-size: 8vw;
      line-height: 10vw;

    }
    
  }
    }
  }
  }
  .tinhTien-container{
    margin: 3vw 2vw;
    font-size: 3.5vw;

    .tieuDe{
      text-align: left;
      font-weight: 600;
      line-height: 8vw;
      color: #04aa6d;
    }

    .tongTien{
      display: flex;
      .tieude{
        width: 48vw;
        text-align: left;
        line-height: 6vw;
        
      }
      .sotien{
        width: 48vw;
        text-align: center;
        line-height: 6vw;
        height: 6vw;
        border-radius: 2vw;
        color: black;
      }
    }
    .qr{
      width: 68vw;
      object-fit: cover;
    }
  
  }
  .nguoiNhan-container{
    margin: 3vw 2vw;
    font-size: 3.5vw;
    .tieuDe{
      text-align: left;
      font-weight: 600;
      line-height: 8vw;
      color: #04aa6d;
    }
    .thongTinNguoiNhan{
      .thongTinChiTiet{
        .noiDung{
          line-height: 6vw;
          width: 96vw;
          text-align: left;
          display: block;
          
        }
      }
    }
  }
  .thongTinShip-container{
    margin: 3vw 2vw;
    font-size: 3.5vw;
    .tieuDe{
      text-align: left;
      font-weight: 600;
      line-height: 8vw;
      color: #04aa6d;
    }
    .noiDung{
      line-height: 6vw;
      width: 96vw;
      text-align: left;
      display: block;
      color: black;
    }
  }
 
  
  }
}

/* PC*/
@media screen and (min-width : 1024px) {
  // .chiTietDonHang-Container{
  //     a{text-decoration: none};
  //     margin-top: 5vw;
  //     padding-top: 3vw;
  //     padding-bottom: 20vw;
   
     
  // }
}
@media print {
.chiTietDonHang-Container{
  .quayLai-tieuDe{
    display: none;
    .quayLai{
      display: none;

    }
    .tieuDe{
      display: none;

    }
}
.tieuDeDonHang{
display: none;
  
}
.sanPham{
display: none;
.tenSanPham-xoa{
  .tenSanPham{
    display: none;

   }
 
}

.dacDiem-themGioHang{
display: none;
.anhSp-tenSp{
display: none;
.tenSp{
  display: none;
}
.gioPhut{
  display: none;
}
}
.giaSanPham{
display: none;
.giaKM{
  display: none;
}
.giaNY-giamGia{
  display: none;
  .giaNY{
    display: none;

  }
  .giamGia{
    display: none;

  }
}

}
.soLuong-SL{
display: none;
.soLuong{
  display: none;
}
.SL{
  display: none;

}

}
.thanhTien-TT{
display: none;

.thanhTien{
  display: none;
}
.TT{
  display: none;
}
}

}
}
.tinhTien{
display: none;
.tongTien{
  display: none;
  .tieude{
    display: none;
  }
  .sotien{
    display: none;
  }
}

}
.thanhToanQrCode-container{
display: none;
.thanhToanQr{
  display: none;
}
.qr{
  display: none;
} 
}
.phancach{
display: none;
}
.thongTinChiTiet{
  display: none;
  .tieuDe{
    display: none;
  

  }
  .noiDung{
    display: none;

  }
}
.tongKet{
  display: none;

  .hoanThanh{
    display: none;
}
.huyDon{
  display: none;
}
.inHoaDon{
  display: none;
}
}
}


  
}

@media screen and (max-width : 1023px) {
 .view{
    .mobile{
      .ThemeBlog-ConTaiNer{
      
        // padding-bottom: 30vw;
        .kiHieu-nhomSanPham2-menu{
          background-color: #04aa6d;
          color: #fff;
          display: flex;
          .nhomSanPham2{
            width: 90vw;
            line-height: 10vw;
            font-size: 3.9vw;
            font-weight: 800;
            text-align: left;
            text-transform: uppercase;
            margin-left: 2vw;
          }  
          .menu{
            width: 8vw;
            line-height: 8vw;
            margin:1vw 1vw 1vw 2vw;
            font-weight: 600;
            font-size: 5vw;
          }
        }
        .sanPham-container{
      
            .sanPham {
              width: 100vw;
                background-color: #fff;
                border-bottom: solid 2vw #e5e5e5;
                .tenSP-themGH{
                  display: flex;
                  .tenSP{
                    width: 66vw;
                    text-align: left;
                    font-size: 3.9vw;
                    font-weight: 600;
                    line-height: 6vw;
                    margin: 2vw 0vw 0vw 2vw;
                    span{
                      color: #fff;
                      font-size: 3.9vw;
                      background-color: red;
                      border-radius: 50%;
                      width: 6vw;
                      line-height: 6vw;
                      display: inline-block;
                      font-weight: 600;
                      text-align: center;
                      text-transform: uppercase;
                    }

  
                    }
                  .themGH{
                    margin: 2vw 2vw 0vw 0vw;
                    line-height: 6vw;
                    width: 30vw;
                    font-size: 3vw;
                    font-weight: 600;
                    background-color: #e5e5e5;
                    border-radius: 3vw;
                    text-transform: uppercase;
                  } 
                  .daThem{
                    margin: 2vw 2vw 0vw 0vw;
                    line-height: 6vw;
                    width: 30vw;
                    color: #fff;
                    font-size: 3vw;
                    font-weight: 600;
                    background-color: red;
                    border-radius: 3vw;
                    text-transform: uppercase;
                  }
                }
                .tieuDe-giaBan-giaCu{
                  margin: 2vw auto 0vw;
                  .tieuDe{
                    text-align: left;
                    padding-left: 2vw;
                    line-height: 5vw;
                    font-size: 3.5vw;
                    font-weight: 600;
                  }
                  .giaBan-giaCu{
                    display: flex;
                    font-size: 3.5vw;
                    .giaBan{
                      width: 68vw;
                      text-align: left;
                      padding-left: 2vw;
                      line-height: 5vw;
                     
                      .giaKM{
                        color: red;
                        font-weight: 600;
                      }
  
                    }
                   
                    .giaCu{
                      width: 32vw;
                      text-align: right;
                      padding-right: 2vw;
                      line-height: 5vw;
                      text-decoration: line-through;
                    }
                  }
                }
                
                .thongTinSp{
                  .tieuDeTT{
                    text-align: left;
                    padding-left: 2vw;
                    line-height: 5vw;
                    font-size: 3.5vw;
                    font-weight: 600;
            
            
                  }
                  .noiDungTT{
                    margin:  auto 2vw;
                    text-align: justify;
                    font-size: 3.3vw;
                    line-height: 5vw;
                    color: #04aa6d;
            
                  }
                }
               .allAnh-container{
                .allAnh1{
                  .anh1{
                    width: 100vw;
                    max-height:133vw ;
                    object-fit: cover;
                  }
                
                }
                .allAnh2{
                  display: flex;
                  background-color: #e5e5e5;
                  gap: 2vw;
                  padding-top: 2vw;

                  .anh1{
                    width: 49vw;
                    height: 100vw;
                    object-fit: cover;
                    
                  }
                  .anh2{
                    width: 49vw;
                    height: 100vw;
                    object-fit: cover;
                    
                  }
                }
                .allAnh3{
                  display: flex;
                  background-color: #e5e5e5;
                  gap: 2vw;
                  padding-top: 2vw;
                  .anh1-anh2{
                  
                    .anh1{
                      width: 49vw;
                      height: 49vw;
                      object-fit: cover;
                      margin-bottom: 2vw;
                      
                    }
                    .anh2{
                      width: 49vw;
                      height: 49vw;
                      object-fit: cover;
                      
                    }
                  }
                  .anh3{
                    width: 49vw;
                    height: 100vw;
                    object-fit: cover;
                    
                  }
                }
                .allAnh4{
                  display: grid;
                  grid-template-columns: 1fr 1fr;
                  background-color: #e5e5e5;
                  gap: 2vw;
                  padding-top: 2vw;
                  .anh4{
                    width: 49vw;
                    height: 49vw;
                    object-fit: cover;
                    
                  }
                }
                .allAnh5{
                  display: grid;
                  grid-template-columns: 1fr 1fr;
                  background-color: #e5e5e5;
                  gap: 2vw;
                  padding-top: 2vw;
                  .anh5{
                    width: 49vw;
                    height: 49vw;
                    object-fit: cover;
                    
                  }
                  .anh6{
                    position: relative;
                    
                    .anh61{
                      width: 49vw;
                      height: 49vw;
                      object-fit: cover;
       
                      
                    
                    }
                    .anh62{
                      width: 49vw;
                      line-height: 49vw;
                      font-size: 16vw;
                      color: #e5e4e5;
                      opacity: 0.6;
                      position: absolute;
                      z-index: 10;
                      top: 0px;
                      left: 0px;
                    }
                  }
                }
               }
               
                   
            }
           
        }
        
    
      
      }
    }
    .pc{
      display: none;
    }
  }
  
}
 @media screen and (min-width : 1024px) {
  .view{
     .mobile{
       display: none;
     }
     .pc{
       .ThemeBlog-ConTaiNer{
     }
   }
   
 }
 }

 
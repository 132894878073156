

/* Mobile & Tablet */
@media screen and (max-width : 1023px) {
  .view{
    .mobile{
      .NhomGiaoHang-ConTaiNer{
        .quayLai-tieuDe{
          width: 100vw;
          display: flex;
          font-size: 3.5vw;
          background-color: #fff;
          border-bottom: #d0d4dd 1px solid;
          position: fixed;
          top: 0px;
            z-index: 1;
          a{
              text-decoration: none;
          }
          .quayLai{
              color: #04aa6d;
              width: 30vw;
              text-align: left;
              line-height: 9vw;
              padding-left: 2vw;
             
          }
            .tieuDe{
                font-size: 3.9vw;
                font-weight: 600;
                color: red;
                text-transform: uppercase;
                width: 40vw;
                line-height: 9vw;
         
      
            }
            .taiKhoanShop{
              width: 30vw;
              text-align: right;
              padding-right: 2vw;
              line-height: 9vw;
              color: black;
        
          }
         
        }
        .phiNenTang{
          margin: 11vw 2vw 0vw;
          font-style: italic;
          font-size: 3vw;
          text-align: right;
          
        }
        .nhomDonHang{
          padding-top: 12vw;
          .trangThai{
            padding-top: 2vw;
            .chonTrangThai{
              background-color: #fff;
              text-align: left;
              padding-left: 2vw;
              border: solid 1px gray;
              width: 96vw;
              text-align: left;
              height: 8vw;
              line-height: 8vw;
              border-radius: 4vw;
            }
          }
        }
        .tieuDe-traHang{
          display: flex;
          margin-top: 3vw;
          font-weight: 600;
          border-bottom: solid 2vw #e5e5e5;
          .tieuDe{
            text-align: left;
            padding-left: 2vw;
            line-height: 8vw;
            width: 70vw;
      
          }
          .traHang{
            line-height: 8vw;
            text-align: right;
            width: 30vw;
            padding-right: 2vw;
            color: #04aa6d;
          }
          .toaDo{
            line-height: 8vw;
            text-align: right;
            width: 30vw;
            padding-right: 2vw;
            color: red;
          }
        }
        .allDonHang{
          margin-bottom: 30vw;
          .donHang-container2{
                  padding: 2vw;
                  border-bottom: solid 2vw #e5e5e5;
                  .ngayThang-container{
                    display: flex;
                    line-height: 6vw;
                    .trangThai{
                      width: 68vw;
                      text-align: left;
                      color: #04aa6d;
                     
                    }
                    .trangThai2{
                      width: 68vw;
                      text-align: left;
                      color: red;
                     
                    }
                    .ngayThang{
                    
                      text-align: right;
                      width: 28vw;
                      
                  }
                  
                 
                  }
                  .phi-coc-daNhan{
                    line-height: 6vw;
                    .coc{
                      width: 96vw;
                      text-align: left;
                    }
                  
                  }
                  .noiGui-diaChi{
                    width: 96vw;
                    text-align: left;
                    .noiGui{
                      line-height: 6vw;
                    }

                  }
                 
          }
         
        }
        .trong{
            font-size: 3.5vw;
            font-weight: 500;
            margin: 5vw auto 100vw;
          
           
        }
       
      }
    
    }
    .pc{
      display: none;
    }
  }
  
}

/* PC*/
@media screen and (min-width : 1024px) {
  .view{
    .mobile{
     display: none;
    }
    .pc{
      .NhomGiaoHang-ConTaiNer{
    
      }
    }
  }
}
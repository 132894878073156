

/* Mobile & Tablet */
@media screen and (max-width : 1023px) {
  .ChonDiaChi-ConTaiNer{
    margin: 0vw vw 2vw;
    font-size: 3.5vw;
    .tieuDe{
        line-height: 6vw;
        text-align: left;
        font-weight: 600;
    }
    .tinh-huyen-xa{
        margin-bottom: 2vw;
        display: flex;
        gap: 2vw;
        select{
            width: 100%;
            height: 8vw;
            border-radius: 4vw;
            border: solid 1px gray;
            text-align-last: left;
            padding-left: 2vw;
        }
    }
    .soNha{
        width: 96vw;
        height: 8vw;
        text-align: left;
        padding-left: 2vw;
        border: solid 1px gray;
        border-radius: 4vw;
    }

  }
}

/* PC*/
@media screen and (min-width : 1024px) {
    .ChonDiaChi-ConTaiNer{
        margin: 0vw vw 1vw;
        .tieuDe{
            line-height: 2vw;
            font-weight: 600;
            text-align: left;
        }
        .tinh-huyen-xa{
            margin-bottom: 1vw;
            display: flex;
            gap: 1vw;
            select{
                width: 100%;
                height: 2vw;
                border-radius: 1vw;
                border: solid 1px gray;
                text-align-last: left;
                padding-left: 1vw;
            }
        }
        .soNha{
            width: 56.8vw;
            margin: 1vw;
            height: 2vw;
            text-align: left;
            padding-left: 1vw;
            border: solid 1px gray;
            border-radius: 1vw;
        }
    
      }
}
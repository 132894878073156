

/* Mobile & Tablet */
@media screen and (max-width : 1023px) {
    .register-containerTo{
        font-size: 3.5vw;
        margin-bottom: 30vw;
        .quayLai-tieuDe{
          display: flex;
          
          background-color: #fff;
          border-bottom: #d0d4dd 1px solid;
          position: fixed;
      
          top: 0px;
            z-index: 1;
          a{
              text-decoration: none;
          }
          .quayLai{
              color: #04aa6d;
              width: 25vw;
              text-align: left;
              line-height: 9vw;
              padding-left: 2vw;
             
          }
          .tieuDe{
            font-size: 3.9vw;
              font-weight: 600;
              color: red;
              text-align: center;
              width: 75vw;
              line-height: 9vw;
              text-transform: uppercase;
              padding-right: 25vw;
    
          }
      }
        .register-container {
            margin-top: 12vw;
          
            .logoDangNhap{
             margin-top: 2vw;
             margin-bottom: 5vw;
             text-align: center;
             img{
                 width: 50vw;
                 height: 50vw;
                 border-radius: 50%;
             }
         }
            .baoLoi{
                color: red;
                
                margin: 2vw auto;
            }
            .dangKi {
                margin: auto 2vw;
                
                .labelDangNhap{
                    text-align: left;
                    font-weight: 600;
                    width: 96vw;
                    line-height: 8vw;
                }
                .inputDangNhap {
                    text-align: left;
                    padding-left: 5vw;
                    width: 96vw;
                    height: 10vw;
                    margin-bottom: 5vw;
                    border-radius: 5vw;
                    
                    font-weight: 600;
                }
                
                .buttonDangNhap {
                    cursor: pointer;
                    color: white;
                    background-color: #04aa6d;
                    width: 96vw;
                    margin-top: 5vw;
                    line-height: 10vw;
                    
                    font-weight: 600;
                    border-radius: 5vw;
                }
        
                
            }
            .login-register {
                margin: 10vw auto 1vw;
                
            }
            .login-register-link {
                text-decoration: none;
                cursor: pointer;
                margin-top: 2vw;
                font-weight: 600;
                
                color: #04aa6d;
            }
        
            
        }
    }
}

/* PC*/
@media screen and (min-width : 1024px) {
    .register-containerTo{
        
        .register-container {
            margin-top: 12vw;
       .fast{
        
        font-weight: 600;
        color: #04aa6d;
       }
       .logoDangNhap{
        margin-top: 2vw;
        margin-bottom: 5vw;
        text-align: center;
        img{
            width: 50vw;
            height: 50vw;
            border-radius: 50%;
            border: solid 1px #04aa6d;
        }
    }
            .login-title {
                font-weight: 700;
                font-size: 2vw;
                text-align: center;
                margin: 0.5vw auto;
               
            }
            form {
                text-align: center;
                
                .labelDangNhap{
                    text-align: left;
                    margin-bottom: 0.1vw;
                    font-size: 2vw;
                    font-weight: 600;
                }
                .inputDangNhap {
                    text-align: center;
                    width: 80%;
                    height: 3vw;
                    margin-bottom: 0.1vw;
                    border-radius: 1vw;
                    font-size: 1.5vw;
                    font-weight: 600;
                }
                
                .buttonDangNhap {
                    cursor: pointer;
                    border: none;
                    color: white;
                    background-color: #04aa6d;
                    margin-top: 1vw;
                    font-size: 2vw;
                    font-weight: 600;
                    border-radius: 1vw;
                    width: 20vw;
                }
        
                
            }
            .login-register {
                font-size: 2vw;
            }
            .login-register-link {
                text-decoration: none;
                cursor: pointer;
                margin-top: 1vw;
                font-weight: 700;
                font-size: 2vw;
            }
            .logoDangNhap{
                margin-top: 1vw;
                margin-bottom: 3vw;
                text-align: center;
                img{
                    width: 6vw;
                    height: 6vw;
                    border-radius: 50%;
                }
            }
            .sloganDangNhap{
                margin-bottom: 2vw;
            }
        
            
        }
    }
}
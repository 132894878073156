

/* Mobile & Tablet */
@media screen and (max-width : 1023px) {
  .SuaMenu-ConTaiNer{
    a{
      text-decoration: none;
    }
    font-size: 3.5vw;
    .quayLai-tieuDe{
      display: flex;
      font-size: 3.5vw;
      background-color: #fff;
      border-bottom: #d0d4dd 1px solid;
      position: fixed;
  
      top: 0px;
        z-index: 1;
      a{
          text-decoration: none;
      }
      .quayLai{
          color: #04aa6d;
          width: 25vw;
          text-align: left;
          line-height: 9vw;
          padding-left: 2vw;
         
      }
      .tieuDe{
        font-size: 3.9vw;
          font-weight: 600;
          color: red;
          text-align: center;
          width: 75vw;
          line-height: 9vw;
          text-transform: uppercase;
          padding-right: 25vw;

      }
  }
    

    .menu-container{
      margin: 12vw 2vw;
      .tieuDe-them{
        .tieuDe{
          width: 86vw;
          text-align: left;
          font-weight: 600;
          line-height: 8vw;
          border-radius: 2vw;
          font-size: 3.9vw;
          font-weight: 600;
        }

      }
        .themNhom{
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 5vw;
          font-size: 3vw;
          margin-bottom: 2vw;
        .tenNhom{
          width: 81vw;
          border: solid 1px #d0d4dd ;
          text-align: left;
          padding-left: 2vw;
          border-radius: 2vw;
          font-size: 3.5vw;
          font-weight: 500;
          height: 8vw;
        }
        .them{
          width: 10vw;
          text-align: center;
          line-height: 8vw;
          color: #04aa6d;
          border-radius: 2vw;
          font-size: 6vw;
          font-weight: 800;
          

        }
      }
      .menu{
        text-align: left;
        .tenNhomXoa{
          display: flex;
          margin: 2vw auto;
          border: solid 1px #d0d4dd ;
          font-size: 3.5vw;
          border-radius: 2vw;
        .tenNhom{
          width: 86vw;
          line-height: 8vw;
         padding-left: 2vw;
          text-transform: uppercase;

        }
        .xoa{
          width: 10vw;
          line-height: 8vw;
          border-radius: 2vw;
          text-align: center;
          color: red;
          font-weight: 600;
          font-size: 3.9vw;

        }
      }
      }
  }
    .sanPham-container{
      margin: 2vw;
      .tieuDe-them{
        display: flex;
        .tieuDe{
          width: 86vw;
          text-align: left;
          font-weight: 600;
          line-height: 8vw;
          border-radius: 2vw;
          font-size: 3.9vw;
          font-weight: 600;
        }
        .them{
          width: 10vw;
          text-align: center;
          line-height: 8vw;
          color: #04aa6d;
          border-radius: 2vw;
          font-size: 6vw;
          font-weight: 800;

        }
      }
      .sanPham{
        margin-bottom: 30vw;
       
        .allSanPham{
          display: flex;
          text-align: left;
          margin: 2vw auto;
          border: solid 1px #d0d4dd ;
          border-radius: 2vw;
          .tenSpHien{
            width: 86vw;
            line-height: 8vw;
           padding-left: 2vw;
           color: #04aa6d;
          }
          .tenSpAn{
            width: 86vw;
            line-height: 8vw;
           padding-left: 2vw;
           color: gray;
          }
          .sua{
            width: 10vw;
            line-height: 8vw;
            border-radius: 2vw;
            text-align: center;
            font-weight: 600;
            font-size: 3.9vw;
            color: gray;
          }
        }

      }
    }

  }
}

/* PC*/
@media screen and (min-width : 1024px) {
    .SuaMenu-ConTaiNer{
        
    }
}


/* Mobile & Tablet */
@media screen and (max-width : 1023px) {
.DonHangMua-container{
  a{text-decoration: none};
  font-size: 3vw;
  .quayLai-tieuDe{
    width: 100vw;
    display: flex;
    font-size: 3.5vw;
    background-color: #fff;
    border-bottom: #d0d4dd 1px solid;
    position: fixed;
    top: 0px;
      z-index: 1;
    a{
        text-decoration: none;
    }
    .quayLai{
        color: #04aa6d;
        width: 35vw;
        text-align: left;
        line-height: 9vw;
        padding-left: 2vw;
       
    }
      .tieuDe{
          font-size: 3.9vw;
          font-weight: 600;
          color: red;
          text-transform: uppercase;
          width: 30vw;
          line-height: 9vw;
   

      }
   
   
  }
  .donHang-all{
    margin: 12vw 2vw 30vw;
    .donHang-container{
      margin: 2vw auto;
      padding: 0vw 2vw;
      border: solid 1px #04aa6d;
      border-radius: 2vw;
      font-size: 3vw;;
      .ngayThang-container2{
          display: flex;
          line-height: 6vw;
          .ngayThang{
            text-align: left;
            width: 46vw;
          }
          .kiemTra{
          width: 46vw;
          .daNhan{
            text-align: right;
            color: #04aa6d;
           
          }
          }
       
        }
      .donHang{
          display: flex;
          justify-content: center;
          align-items: center;
          .SanPham{
              width: 42vw;
         
              .tenSp-dacDiem-soLuong{
               .tenSp{
              text-align: left;
              text-transform: uppercase;
              font-weight: 600;
              color: red;
              line-height: 5vw;
               }
               .dacDiem-soLuong{
                    display: flex;
                   .dacDiem{
                       width: 26vw;
                       line-height: 5vw;

                   }
                   .soLuong{
                       width: 16vw;
                       line-height: 5vw;
   
                   }
               }
             
           }
           }
          .soBan{
                  line-height: 5vw;
                  width: 25vw;
         

          }
          .tinhTrang{
                  width: 25vw;
                  color: red;
                  line-height: 5vw;
          
          }
      }
    
}
    .donHangTrong{
        font-size: 3.5vw;
        line-height: 8vw;
    }
  }
 
  
  
}
}

/* PC*/
@media screen and (min-width : 1024px) {
    .DonHangMua-ConTaiNer{
        
    }
}


/* Mobile & Tablet */
@media screen and (max-width : 1023px) {
  .view{
    .mobile{
      .chiTietDonHangNhan-ConTaiNer{
        font-size: 3.5vw;
        margin-bottom: 30vw;
        .quayLai-tieuDe{
          width: 100vw;
          display: flex;
          font-size: 3.5vw;
          background-color: #fff;
          border-bottom: #d0d4dd 1px solid;
          position: fixed;
          top: 0px;
            z-index: 1;
          a{
              text-decoration: none;
          }
          .quayLai{
              color: #04aa6d;
              width: 25vw;
              text-align: left;
              line-height: 9vw;
              padding-left: 2vw;
             
          }
          .tieuDe{
            font-size: 3.9vw;
              font-weight: 600;
              color: red;
              text-align-last: center;
              width: 66vw;
              line-height: 9vw;
              text-transform: uppercase;
              padding-right: 16vw;
             
          }
          .inHoaDon{
            width: 9vw;
            text-align: center;
            line-height: 9vw;
            font-size: 5vw;
           
          }

         
        }
        .trangThaiDh-huyDon{
          margin:12vw 2vw auto;
          font-weight: 600;
          display: flex;
          .trangThaiDh{
            text-align: left;
            line-height: 6vw;
            width: 60vw;
            overflow: hidden;
          }
          .huyDon{
            text-align: right;
            line-height: 6vw;
            width: 36vw;
            color: #04aa6d;
          }
         
        }
        .chiTiet{
          margin: 2vw 2vw auto;   
          .noiGui-thongTin{
            margin: 2vw auto 0vw;
            .tieuDe{
              width: 96vw;
              line-height: 6vw;
              font-weight: 600;
              
              text-align: left;
     

            }
            .noiDung{
              display: block;
              width: 96vw;
              text-align: left;
              line-height: 6vw;
              padding-left: 2vw;
              text-decoration: none;
              color: #000;
            }
          }
        }
        .thanhToan-container{
          margin: 2vw;
          .tieuDe{
            width: 96vw;
            line-height: 6vw;
            font-weight: 600;
            text-align: left;
          }
          .noiDung{
            width: 96vw;
            text-align: left;
            line-height: 6vw;
            padding-left: 2vw;
          }
          .soTien{
            width: 96vw;
            text-align: left;
            line-height: 6vw;
            padding-left: 2vw;
            color: red;
          }
          .qr{
            object-fit: cover;
            width: 68vw;
          }
        }
        .phimChucNang-container{
          margin: 5vw 2vw 0vw;
          font-weight: 600;
          .phim1{
            border-radius: 4vw;
            width: 96vw;
            color: #fff;
            background-color: #04aa6d;
            line-height: 8vw;
          }
          .phim2-phim3{
            display: flex;
            gap: 2vw;
            .phim2{
              width: 47vw;
              border-radius: 4vw;
              color: #fff;
              background-color: red;
              line-height: 8vw;

            }
            .phim3{
              width: 47vw;
              border-radius: 4vw;
              color: #fff;
              background-color: #04aa6d;
              line-height: 8vw;
            }
          }
          .phim4-phim5{
            display: flex;
            gap: 2vw;
            font-size: 3.9vw;
            .phimXanh{
              width: 47vw;
              color: #04aa6d;
              // border-radius: 4vw;
              // color: #fff;
              // background-color: #04aa6d;
              line-height: 8vw;

            }
            .phimDo{
              width: 47vw;
              color: red;
              // border-radius: 4vw;
              // color: #fff;
              // background-color: red;
              line-height: 8vw;
              
            }
            .phimXam{
              width: 47vw;
              // color: gray;
              // border-radius: 4vw;
              // background-color: #e5e5e5;
              line-height: 8vw;
              color: gray;
              
            }

          }
          .xacNhan{
            color: red;
          }
           
            
    
      
        }
        .luuY-ConTainer{
          margin: 5vw 2vw 30vw;
          .luuY{
            .tieuDe{
              text-align: left;
              font-weight: 600;
              line-height: 6vw;
            }
            .noiDung{
              text-align: left;
              line-height: 6vw;
              font-style: oblique;
            }
          }
        
        }
        .daCoNguoiNhan{
          position: fixed;
          z-index: 1;
          left: 0;
          top: 0;
          width: 100vw;
          height: 100vh;
          overflow: auto;
          background-color: #fff;
          .thongBao{
            position: fixed;
            z-index: 9;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            .noiDung{
              color: red;
              font-weight: 600;
              font-size: 5vw;
              width: 100vw;

            }
            .quayLai{
              margin: 5vw 32vw;
              width: 36vw;
              border-radius: 4vw;
              color: #fff;
              background-color: #04aa6d;
              line-height: 8vw;
              font-weight: 600;

            }
          }
        }
       
        
      }
    }
    .pc{
      display: none;
    }
  }
  
}

/* PC*/
@media screen and (min-width : 1024px) {
  .view{
    .mobile{
     display: none;
    }
    .pc{
      .NhanGiaoHang-ConTaiNer{
    
      }
    }
  }
}

/* Mobile & Tablet */
@media screen and (max-width : 1023px) {
    .addShop-container{
        font-size: 3.5vw;
        .quayLai-tieuDe{
          display: flex;
          font-size: 3.5vw;
          background-color: #fff;
          border-bottom: #d0d4dd 1px solid;
          position: fixed;
      
          top: 0px;
            z-index: 1;
          a{
              text-decoration: none;
          }
          .quayLai{
              color: #04aa6d;
              width: 25vw;
              text-align: left;
              line-height: 9vw;
              padding-left: 2vw;
          }
          .tieuDe{
            font-size: 3.9vw;
              font-weight: 600;
              color: red;
              text-align: center;
              width: 75vw;
              line-height: 9vw;
              text-transform: uppercase;
              padding-right: 25vw;
    
          }
      }
        .addShop{
            margin: 12vw 2vw 30vw;
         .tenShop-TS{
            margin: 3vw auto;
            .tenShop{
                line-height: 8vw;
                font-weight: 600;
                text-align: left;
                line-height: 6vw;
            }
            .TS{
                width: 96vw;
                height: 8vw;
                text-align: left;
                padding-left: 2vw;
                border: solid 1px gray;
                border-radius: 4vw;
            }
         }
         .luuY{
            font-size: 3.5vw;
            text-align: left;
            margin: 3vw auto 0vw;
            font-style:italic;
            line-height: 6vw;
            span{
                font-weight: 600;
                line-height: 6vw;
                text-align: center;
                color: #04aa6d;
                text-decoration-line:overline;
                text-decoration-color:red;
                text-decoration-style:double;
              }
         }
         .luuShop{
            width: 96vw;
            background-color: #04aa6d;
            line-height: 8vw;
            font-size: 3.5vw;
            font-weight: 600;
            color: #fff;
            border-radius: 4vw;
            margin: 10vw auto;
            
        }
        .daluuShop{
            width: 96vw;
            background-color: red;
            line-height: 8vw;
            font-size: 3.5vw;
            font-weight: 600;
            color: #fff;
            border-radius: 4vw;
            margin: 10vw auto;
        }
        } 
      
    }
    
    
}

/* PC*/
@media screen and (min-width : 1024px) {
    // .addShop{
    //     margin-bottom: 20vw;
    //     .banner-container{
    //         .bannerFormregis2{
    //             width: 100%;
    //             height: 25vw;
    //             object-fit: cover;
    //             position: relative;
    //             background-color: rgb(242, 255, 0);
    //         .banner {
    //             width: 100%;
    //             height:40vw;
    //             object-fit: cover;
    //         }
    //         }
    //     }
    //     .containerTieuChiFormregis {
    //         display: flex;
    //         margin-top: 1vw;
    //         margin-left: 1vw;
    //         margin-right: 1vw;
    //         text-align: center;
    //         .tieuChiFormregis {
    //             width: 30%;
    //             font-size: 2vw;
    //             padding: 0.5;
    //             text-align: center;
    //             border: solid 0.11vw rgb(143, 140, 140);
    //         }
    //         .myNoiDungFormregis2 {
    //             font-size: 2vw;
    //             padding: 0.5;
    //             width: 70%;
    //             border: solid 0.1vw rgb(143, 140, 140);
    //             text-align: center;
    //             border-radius: 1vw;
             
    //             select{
    //                 text-align: center;
    //                 width: 30%;
    //                 font-size: 2vw;
    //                 padding: 0.5;
    //                 border: solid 0.1vw rgb(143, 140, 140);
    //                 border-radius: 1vw;
    //                 margin-right: 0.5vw;
    //                 option {
    //                     // text-align: center;
                        
    //                 }
    //             }
                
    //         }
    //         .noiDungFormregis3{
    //             width: 70%;
    //             border: solid 0.1vw rgb(143, 140, 140);
    //             text-align: center;
    //             font-size: 2vw;
    //             padding: 0.5;
    //             .inputFormregis3 {
    //                 text-align: center;
    //                 width: 98%;
    //                 border: none;
    //                 font-size: 2vw;
    //                 padding: 0.5;
    //                 margin: 0.5vw auto;
    //             }
    //         }
    //     }
    //     .huyBo-luuShop{
    //         .huyBo{

    //         }
    //         .luuShop{
    //             cursor: pointer;
    //             border-radius: 1vw;
    //             width: 25vw;
    //             font-size: 2vw;
    //             font-weight: 600;
    //             text-align: center;
    //             background-color: #04aa6d;
    //             margin-top: 2vw;
    //             margin-bottom: 20vw;
    //             padding: 0.5vw;
    //         }
    //     }
        
    // } 
}
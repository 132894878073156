 /* Mobile & Tablet */
 @media screen and (max-width : 1023px) {
  .view{
     .mobile{
       .ThuNghiem-ConTaiNer{
        margin-top: 10vw;
       }
     }
     .pc{
       display: none;
     }
   }
   
 }
 @keyframes d1 {to{clip-path: inset(0 -34% 0 0)}}
  /* Pc */
  @media screen and (min-width : 1024px) {
   .view{
      .mobile{
        display: none;
      }
      .pc{
        .ThuNghiem-ConTaiNer{
          margin-top: 10vw;
         }
    }
    
  }
  }
  

  /* Mobile & Tablet */
@media screen and (max-width : 1023px) {
    .containerMiniGame {
        text-align: center;
        position: absolute;
          top: 12vw;
          padding-bottom: 10vw;
          .container-quayso-phanthuong {
              .soMayMan-PhanThuong {
                  display: flex;
      
                  .SoMayMan {
                      width: 50%;
                      height: 25vw;
                      border: solid 0.1vw red;
                      margin: 1vw;
                      align-items: center;
                      border-radius: 1vw;
                      background-color: #88d3b4;
                      .tieude {
                          margin-top: 2vw;
                          font-size: 3vw;
                          font-weight: 600;
                          color: red;
                      }
                      .soMayMan1 {
                          display: flex;
                          justify-content: center;
                          align-items: center;
                          text-align: center;
                          margin-top: 2.5vw;
                          font-size: 10vw;
                          font-weight: 600;
                          color: red;
                          text-shadow: 0.2vw 0.5vw #e6f702;
                      }
                      .soMayMan2 {
                          display: flex;
                          justify-content: center;
                          align-items: center;
                          text-align: center;
                          margin-top: 5.5vw;
                          .spinner-10 {
                              font-size: 10vw;
                              font-weight: 600;
                              width: 6vw;
                              aspect-ratio: 1;
                              color: #854f1d;
                              border-radius: 50%;
                              display: grid;
                              background: conic-gradient(
                                          from 90deg at 0.4vw 0.4vw,
                                          #0000 90deg,
                                          currentColor 0
                                      ) -0.4vw -0.4vw / calc(50% + 0.2vw) calc(50% + 0.2vw),
                                  radial-gradient(
                                          farthest-side,
                                          currentColor 0.6vw,
                                          #0000 0.7vw calc(100% - 0.6vw),
                                          currentColor calc(100% - 0.5vw)
                                      )
                                      no-repeat;
                              animation: s10 2s infinite linear;
                              position: relative;
                          }
                          .spinner-10:before {
                              content: "";
                              border-radius: inherit;
                              background: inherit;
                              transform: rotate(45deg);
                          }
                          @keyframes s10 {
                              to {
                                  transform: rotate(0.5turn);
                              }
                          }
      
                          /**/
                          body {
                              display: grid;
                              grid-template-columns: repeat(
                                  auto-fit,
                                  minmax(25vw, 1fr)
                              );
                              grid-auto-rows: 13vw;
                              place-items: center;
                          }
      
                          * {
                              box-sizing: border-box;
                          }
                      }
                  }
                  .phanThuong {
                      width: 50%;
                      height: 25vw;
                      border: solid 0.1vw red;
                      margin: 1vw;
                      align-items: center;
                      border-radius: 1vw;
                      background-color: #88d3b4;
                      .tieude {
                          margin-top: 2vw;
                          font-size: 3vw;
                          font-weight: 600;
                          color: red;
                          margin-bottom: 1vw;
                      }
                      .phanThuong1 {
                          margin: auto;
                          width: 16vw;
                          height: 16vw;
                          background-color: #fff;
                          border-radius: 1vw;
                          object-fit: cover;
                          .phanThuong1HopQua {
                              margin: auto;
                              width: 15vw;
                              height: 15vw;
                              background-color: #fff;
                              border-radius: 1vw;
                              object-fit: cover;
                          }
                      }
      
                      .phanThuong2 {
                          display: flex;
                          justify-content: center;
                          align-items: center;
                          text-align: center;
                          margin-top: 5.5vw;
                          .spinner-10 {
                              font-size: 10vw;
                              font-weight: 600;
                              width: 6vw;
                              aspect-ratio: 1;
                              color: #854f1d;
                              border-radius: 50%;
                              display: grid;
                              background: conic-gradient(
                                          from 90deg at 0.4vw 0.4vw,
                                          #0000 90deg,
                                          currentColor 0
                                      ) -0.4vw -0.4vw / calc(50% + 0.2vw) calc(50% + 0.2vw),
                                  radial-gradient(
                                          farthest-side,
                                          currentColor 0.6vw,
                                          #0000 0.7vw calc(100% - 0.6vw),
                                          currentColor calc(100% - 0.5vw)
                                      )
                                      no-repeat;
                              animation: s10 2s infinite linear;
                              position: relative;
                          }
                          .spinner-10:before {
                              content: "";
                              border-radius: inherit;
                              background: inherit;
                              transform: rotate(45deg);
                          }
                          @keyframes s10 {
                              to {
                                  transform: rotate(0.5turn);
                              }
                          }
      
                          /**/
                          body {
                              display: grid;
                              grid-template-columns: repeat(
                                  auto-fit,
                                  minmax(25vw, 1fr)
                              );
                              grid-auto-rows: 13vw;
                              place-items: center;
                          }
      
                          * {
                              box-sizing: border-box;
                          }
                      }
                      .phanThuong3 {
                          .phanThuongcash {
                              display: flex;
                              font-size: 9vw;
                              font-weight: 500;
                              color: gold;
                              width: 25vw;
                              height: 10vw;
                              margin: auto;
                              justify-content: center;
                              align-items: center;
      
                              padding-top: 5vw;
                              .gold1 {
                                  width: 7vw;
                                  height: 7vw;
                                  margin-top: 1.4vw;
                              }
                          }
                      }
                  }
              }
          }
          .nutQuaySo {
              .quaySo {
                  width: 30vw;
                  height: 12vw;
                  border-radius: 2vw;
                  margin-top: 2vw;
                  background-color: red;
                  font-weight: 600;
                  margin-bottom: 5vw;
                  font-size: 3vw;
                 
              }
              .quaySo2{
                  width: 30vw;
                  height: 12vw;
                  border-radius: 2vw; 
                  font-weight: 600;
                  margin-bottom: 5vw;
                  margin-top: 2vw;
                  font-size: 3vw;
              }
          }
          h2 {
              font-size: 5vw;
              font-weight: 600;
              margin-bottom: 2vw;
              padding: 2vw;
          }
          .danhSachGiaiThuong {
              border: solid 0.05vw rgb(143, 140, 140);
              margin: 0vw 0.5vw;
              
              .Danhmuc {
                  display: grid;
                  grid-template-columns: 1fr 1fr 1fr;
                  background-color: #88d3b4;
                  .hang1 {
                      border: solid 0.05vw rgb(143, 140, 140);
                      font-weight: 600;
                      font-size: 3vw;
                      padding: 
                      2vw 0vw;
                  }
              }
              .danhSach {
                  display: grid;
                  grid-template-columns: 1fr 1fr 1fr;
          
                  .hang2 {
                      
                      border: solid 0.05vw rgb(143, 140, 140);
                      font-size: 1.6vw;
      
                      img {
                          width: 15vw;
                          height: 15vw;
                          padding: 1vw;
                          // object-fit: cover;
                      }
                  }
                  .tenGiai {
                      font-size: 3vw;
                      font-weight: 600;
                      padding: 10vw 0px;
                      
                  }
                  .sotrung {
                      font-size: 2vw;
                      font-weight: 600;
                      padding: 9vw 0px;
                     
                  }
              }
          }
      
          .huongDanMiniGame {
              margin: 3vw 2vw 8vw 2vw;
              h2 {
                  font-size: 5vw;
                  font-weight: 600;
                  margin-bottom: 2vw;
                  margin-top: 4vw;
              }
              p {
               
                  margin: 2vw;
                  font-size: 2.8vw;
                  text-align: justify;
              }
              .capnhatthongtin{
                  text-align: center;
                  margin-top: 5vw;
                  background-color: #04aa6d;
                  font-size: 5vw;
                  font-weight: 600;
                   padding: 2vw;
                  border-radius: 3vw;
                  cursor: pointer;
                  a{
                      text-decoration: none;
                      color: #fff;
                  }
              }
          }
      }
}

/* PC*/
@media screen and (min-width : 1024px) {
   
}
@media screen and (max-width : 1023px) {
 .view{
    .mobile{
      .NganHangShop-ConTaiNer{
        .nganHang-container{
          font-size: 3.5vw;
          .quayLai-tieuDe{
              display: flex;
              font-size: 3.5vw;
              background-color: #fff;
              border-bottom: #d0d4dd 1px solid;
              position: fixed;
              top: 0px;
                z-index: 1;
              a{
                  text-decoration: none;
              }
              .quayLai{
                  color: #04aa6d;
                  width: 25vw;
                  text-align: left;
                  line-height: 9vw;
                  padding-left: 2vw;
                 
              }
              .tieuDe{
                font-size: 3.9vw;
                  font-weight: 600;
                  color: red;
                  text-align: center;
                  width: 75vw;
                  line-height: 9vw;
                  text-transform: uppercase;
                  padding-right: 25vw;
        
              }
          }
       .ttTaiKhoan{
          margin: 12vw 2vw auto;
          line-height: 6vw;
          font-weight: 600;
          text-align: left;
       }
       .chiTietTaiKhoan{
          text-align: left;
          margin-left: 2vw;
          line-height: 5vw;
  
       }
       .nhapSoTien{
          margin: 5vw auto;
          color: red;
          font-weight: 600;
          font-size: 3.9vw;
          line-height: 8vw;
          width: 60vw;
          text-align: center;
          border: solid 1px gray;
          border-radius: 4vw;
  
       }
       
       .maQr{
          line-height: 6vw;
          font-weight: 600;
          text-align: left;
          margin: 5vw 2vw 0vw;
  
       }
       .qr{
          width: 96vw;
          object-fit: cover;
          margin-bottom: 30vw;
       }
        }
      }
    }
    .pc{
      display: none;
    }
  }
  
}
 @media screen and (min-width : 1024px) {
  .view{
     .mobile{
       display: none;
     }
     .pc{
       .NganHangShop-ConTaiNer{
     }
   }
   
 }
 }
 


/* Mobile & Tablet */
@media screen and (max-width : 1023px) {
  .UpdateMyDetail-ConTaiNer{
    font-size: 3.5vw;
    margin-bottom: 30vw;
    .quayLai-tieuDe{
      display: flex;
      font-size: 3.5vw;
      background-color: #fff;
      border-bottom: #d0d4dd 1px solid;
      position: fixed;
  
      top: 0px;
        z-index: 1;
      a{
          text-decoration: none;
      }
      .quayLai{
          color: #04aa6d;
          width: 25vw;
          text-align: left;
          line-height: 9vw;
          padding-left: 2vw;
         
      }
      .tieuDe{
        font-size: 3.9vw;
          font-weight: 600;
          color: red;
          text-align: center;
          width: 75vw;
          line-height: 9vw;
          text-transform: uppercase;
          padding-right: 25vw;

      }
  }
  .thongTinCaNhan-container{
    margin: 12vw auto 10vw;
  
  
  
    .thongTin{
  
      display: flex;
      .hoTen-sdt{
        width: 50vw;
        .hoTen-input{
          margin-bottom: 2vw;
          margin-left: 2vw;
          .hoTen{
            line-height: 6vw;
            font-weight: 600;
            font-size: 3.5vw;
            text-align: left;
        }
          .input{
          width: 48vw;
          height: 8vw;
          text-align: left;
          padding-left: 2vw;
          border: solid 1px gray;
          border-radius: 4vw;
        }
      }
      .gioiTinh-select{
        margin-bottom: 2vw;
        margin-left: 2vw;
        width: 50vw;
        .gioiTinh{
          line-height: 6vw;
          font-weight: 600;
          font-size: 3.5vw;
          text-align: left;
    
    
        }
        .select{
          width: 48vw;
          height: 8vw;
          margin-right:50vw;
          border-radius: 4vw;
          border: solid 1px gray;
          text-align: left;
          padding-left: 2vw;
    
        }
      }
      }
      .avatar-taiKhoan{
        width: 46vw;
        padding-top: 6vw;
        .avatar{
          width: 31vw;
          height:31vw ;
          border-radius: 50%;
          object-fit: cover;
    
        }
        .taiKhoan{
          text-align: center;
          line-height: 8vw;
  
      
        }
    
        
      }
    }
  
    .ngaySinh-select{
      margin:auto 2vw;
      .ngaySinh{
        line-height: 6vw;
        font-weight: 600;
        font-size: 3.5vw;
        text-align: left;
  
      }
      .select-container{
        display: flex;
        gap: 2vw;
        .select{
          width: 100%;
          height: 8vw;
          border: solid 1px gray;
          border-radius: 4vw;
          text-align: left;
          padding-left: 2vw;
    
        }
      }
     
    }
    .diaChi{
      margin: auto 2vw;
    }
    .toaDo{
      margin: 2vw;
      .tDo-vTri{
        display: flex;
        .tDo{
          width: 48vw;
          text-align: left;
          font-weight: 600;
          line-height: 8vw;

        }
        .vTri{
          width: 48vw;
          text-align: right;
          font-weight: 600;
          line-height: 8vw;
          color: #04aa6d;

        }
      }
      .kinhDo-viDo{
        display: flex;
        gap: 2vw;
        .kinhDo{
          width: 47vw;
          border: solid 1px gray;
          text-align: left;
          padding-left: 2vw;
          line-height: 8vw;
          border-radius: 4vw;
          overflow: hidden;
          

        }
        .viDo{
          width: 47vw;
          border: solid 1px gray;
          text-align: left;
          padding-left: 2vw;
          line-height: 8vw;
          border-radius: 4vw;
          overflow: hidden;


        }
      }

    }
    .gmail-input{
      margin-top: 2vw;
      // border: solid 1px gray;
      .gmail{
        line-height: 6vw;
        font-weight: 600;
        font-size: 3.5vw;
        text-align: left;
        padding-left: 2vw;
      }
      .input{
        width: 96vw;
        height: 8vw;
        text-align: left;
        padding-left: 2vw;
        border: solid 1px gray;
        border-radius: 4vw;
      }
    }
  }
    .tkNganHang-container{
      margin: 12vw 2vw 10vw;
  .taikhoan{
    .ttTaiKhoan{
      width: 50vw;
      .stk{
        text-align: left;
        line-height: 8vw;
        font-weight: 600;
  
      }
      select, option,input{
        text-align: left;
        padding-left: 2vw;
        height: 8vw;
        width: 96vw;
        border-radius: 4vw;
        border: solid 1px gray;
        margin-bottom: 2vw;
        text-transform: uppercase;
    }
    }
    .qr-container{
      width: 96vw;
      padding: auto 14vw;
      .maQr{
        width: 68vw;
        object-fit: cover;
      }
    }
  }
    }
    .luuThongTin{
      width: 96vw;
      background-color: #04aa6d;
      line-height: 8vw;
      font-weight: 600;
      color: #fff;
      border-radius: 4vw;
      margin: 10vw auto;
    }
    }
}

/* PC*/
@media screen and (min-width : 1024px) {
    .UpdateMyDetail-ConTaiNer{
        
    }
}
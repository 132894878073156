

/* Mobile & Tablet */
@media screen and (max-width : 1023px) {
  .UpdateSanPham-ConTaiNer{
    font-size: 3.5vw;

    .quayLai-tieuDe{
        display: flex;
        background-color: #fff;
        border-bottom: #d0d4dd 1px solid;
        position: fixed;
        top: 0px;
        z-index: 1;
        a{
            text-decoration: none;
        }
        .quayLai{
            color: #04aa6d;
            width: 25vw;
            text-align: left;
            line-height: 9vw;
            padding-left: 2vw;
           
        }
        .tieuDe{
          font-size: 3.9vw;
            font-weight: 600;
            color: red;
            text-align: center;
            width: 75vw;
            line-height: 9vw;
            text-transform: uppercase;
            padding-right: 25vw;
  
        }
    }
    .AddSp{
        margin: 12vw 1vw 30vw;
      .nhomTen{
          .nhomSanPham{
              width: 50vw;
              height: 8vw;
              margin: 0vw 48vw 3vw auto;
              border-radius: 1vw;
              border: solid 1px gray;
              text-align-last:left;
              padding-left: 2vw;
              option{
                text-align-last:left;
                padding-left: 2vw;
              }
  
          }
          .tenSp-hienAn{
            display: flex;
            gap:1vw;
            .tenSanPham{
              width: 79vw;
              height: 8vw;
              border-radius: 1vw;
              text-align: left;
              padding-left: 2vw;
              border: solid 1px gray;
    
            }
            .hien{
              width: 19vw;
              line-height: 8vw;
              background-color: #04aa6d;
              color: #fff;
              font-weight: 600;
              border-radius: 1vw;

            }
            .an{
              width: 19vw;
              line-height: 8vw;
              background-color: #d0d4dd;
              border-radius: 1vw;
              font-weight: 600;
            }
          }
        
      }
  
      .phanLoaiSanPham{
        font-weight: 600;
        text-align: left;
        line-height: 6vw;
        margin-top: 3vw;
  
      }
      .chiTietPhanLoai2{
        display: flex;
        gap:1vw;
        margin-bottom: 2vw;
        .uploadAnh-container{
          .uploadAnh{
            .anhPreview{
              height: 24vw;
              width: 18vw;
              object-fit: cover;
              border-radius: 2vw;
              border: solid 1px gray;
            }
            .themAnh{
              line-height: 24vw;
              font-size: 10vw;
              width: 18vw;
              border-radius: 2vw;
              color: #fff;
              border: solid 1px #04aa6d;
              background-image: linear-gradient(0deg, #04aa6d 31%, #ffffff 100%);
              
              
             
            }
          }
         }
        .phanLoai-container{
            .tenDD-giaV-giaNY-giaKM{
                display: flex;
                gap:1vw;
                .tenDD-themInput{
                    .tenDD{
                        width: 19vw;
                        font-size: 2.2vw;
                        text-align: left;
                        line-height: 5vw;
                    }
                    .themInput{
                      width: 19vw;
                      font-size: 2.6vw;  
                      text-align: left;
                      height: 7vw;
                      border-radius: 1vw;
                      border: solid 1px gray;
                      text-align-last:left;
                      padding-left: 1vw;
                    }
                }
                .them{
                    background-color: #04aa6d;
                    color: white;
                    text-align: center;
                    font-size: 5vw;
                    line-height: 7vw;
                    height: 7vw;
                    border-radius: 1vw;
                    width: 19vw;
                    font-weight: 600;
                    margin-top: 5vw;
    
                }
                .xoa{
                    background-color: red;
                    color: white;
                    text-align: center;
                    font-size: 5vw;
                    line-height: 7vw;
                    height: 7vw;
                    border-radius: 1vw;
                    width: 19vw;
                    font-weight: 600;
                    margin-top: 5vw;

                }
               
    
             } 
        }
      }
      .thongTinSanPham{
        .tieuDe{
          margin-top: 3vw;
          font-weight: 600;
          text-align: left;
          width: 98vw;
          line-height: 6vw;
        }
        .noiDung{
          text-align: justify;
          line-height: 5vw;
          min-height: 20vw;
          max-width: 98vw;
          object-fit: cover;

        }

      }
      .luuY{
      margin-top: 3vw;
      line-height: 5vw;
      text-align: justify;
      color: red;
      font-style: italic;
      font-size: 3vw;
    }
      .closeLuu{
          display: flex;
          gap: 1vw;
          margin: 10vw 0vw;
          .close{
            text-decoration: none;
            width: 48.5vw;
            font-weight: 600;
            line-height: 9vw;
            border-radius: 1vw;
            background-color: red;
            color: #fff;
  
          }
          .luu{
            font-weight: 600;
            width: 48.5vw;
            line-height: 9vw;
            border-radius: 1vw;
            background-color: #04aa6d;
            color: #fff;
  
          }
      }
     }

  }
}

/* PC*/
@media screen and (min-width : 1024px) {
    .UpdateSanPham-ConTaiNer{
        
    }
}

/* Mobile & Tablet */
@media screen and (max-width : 1023px) {
    .donHangMoi-container{
        a{text-decoration: none};
  font-size: 3vw;
  .quayLai-tieuDe{
    width: 100vw;
    display: flex;
    font-size: 3.5vw;
    background-color: #fff;
    border-bottom: #d0d4dd 1px solid;
    position: fixed;

    top: 0px;
      z-index: 1;
    a{
        text-decoration: none;
    }
    .quayLai{
        color: #04aa6d;
        width: 35vw;
        text-align: left;
        line-height: 9vw;
        padding-left: 2vw;
       
    }
      .donHang{
          font-size: 3.9vw;
          font-weight: 600;
          color: red;
          text-transform: uppercase;
          width: 30vw;
          line-height: 9vw;
   

      }
      .taiKhoanShop{
        width: 35vw;
        text-align: right;
        padding-right: 2vw;
        line-height: 9vw;
        color: red;
  
    }
  }
  
  .chonDanhMuc{
    margin: 12vw 2vw auto;
    .daChon{
      display: block;
      font-size: 3.5vw;
      font-weight: 600;
      color: red;
      text-align: left;
      line-height: 8vw;
      border-bottom: solid 1px #d0d4dd ;
    }
    .chuaChon{
      display: block;
      font-size: 3.5vw;
      font-weight: 600;
      color: #04aa6d;
      text-align: left;
      line-height: 8vw;
      border-bottom: solid 1px #d0d4dd ;
    }
  }
  .phiNenTang-container{
    display: flex;
    margin: 0vw 2vw;
    border-bottom: solid 1px #d0d4dd ;
    .tieuDe{
        width: 48vw;
        text-align: left;
        font-size: 3.5vw;
        font-weight: 600;
        line-height: 8vw;
    }
    .chonDonHang{
        margin-top: 1vw;
        width: 48vw;
        font-size: 3.5vw;
        color: #04aa6d;
        text-align-last:center ;
        height: 6vw;
        font-weight: 600;
        border-radius: 2vw;
        border: solid 1px gray;
        
    }
  }
  
  .donHang-all{
    margin: 5vw 2vw 30vw;
    .tieuDeDH{
        line-height: 6vw;
        font-weight: 600;
        font-size: 3.5vw;
        text-align: center;
        text-transform: uppercase;
    }
    .noiNhan{
        margin-top: 5vw;
        line-height: 6vw;
        font-weight: 600;
        font-size: 3.5vw;
        text-align: left;
    }
    .donHang-container{
            margin: 2vw auto;
            padding: 0vw 2vw;
            border: solid 1px #04aa6d;
            border-radius: 2vw;
            font-size: 3vw;;
            .ngayThang-container2{
                display: flex;
                line-height: 6vw;
                .ngayThang{
                  text-align: left;
                  width: 46vw;
                }
                .kiemTra{
                width: 46vw;
                .daNhan{
                  text-align: right;
                  color: #04aa6d;
                 
                }
                }
             
              }
            .donHang{
                display: flex;
                justify-content: center;
                align-items: center;
                .SanPham{
                    width: 42vw;
               
                    .tenSp-dacDiem-soLuong{
                     .tenSp{
                    text-align: left;
                    font-weight: 600;
                    color: red;
                    line-height: 5vw;
                     }
                     .dacDiem-soLuong{
                          display: flex;
                         .dacDiem{
                             width: 26vw;
                             line-height: 5vw;
     
                         }
                         .soLuong{
                             width: 16vw;
                             line-height: 5vw;
         
                         }
                     }
                   
                 }
                 }
                .soBan{
                        line-height: 5vw;
                        width: 25vw;
               

                }
                .xemChiTiet{
                        width: 25vw;
                        color: red;
                        line-height: 5vw;
                
                }
            }
          
    }
    .donHangTrong{
            line-height: 6vw;
      
            font-size: 3.5vw;
            text-align: center;
    }
    }
}
}

/* PC*/
@media screen and (min-width : 1024px) {

}
@media print {
    .menu{display: none;}
}
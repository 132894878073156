@media screen and (max-width : 1023px) {
 .view{
    .mobile{
      .ThemDonHangGiao-ConTaiNer{
        font-size: 3.5vw;
        .quayLai-tieuDe{
          width: 100vw;
          display: flex;
          font-size: 3.5vw;
          background-color: #fff;
          border-bottom: #d0d4dd 1px solid;
          position: fixed;
          top: 0px;
            z-index: 1;
          a{
              text-decoration: none;
          }
          .quayLai{
              color: #04aa6d;
              width: 30vw;
              text-align: left;
              line-height: 9vw;
              padding-left: 2vw;
             
          }
            .tieuDe{
                font-size: 3.9vw;
                font-weight: 600;
                color: red;
                text-transform: uppercase;
                width: 40vw;
                line-height: 9vw;
         
      
            }
            .taiKhoanShop{
              width: 30vw;
              text-align: right;
              padding-right: 2vw;
              line-height: 9vw;
              color: black;
        
          }
         
        }
        .noiGui{
          margin: 12vw 2vw 0vw;
          .tieuDe{
            text-align: left;
            font-weight: 600;
            line-height: 6vw;
          }
          
          .thongTin{
            line-height: 6vw;
            text-align: left;
            width: 96vw;
          }
        }
        .noiNhan{
          margin: 3vw 2vw 0vw;
          .tieuDeNoiGui{
            text-align: left;
            font-weight: 600;
            line-height: 6vw;
          }
          .hoTen-thongTin{
            .hoTen{
              width: 96vw;
              text-align: left;
              line-height: 6vw;
              font-weight: 600;
            }
            .thongTin{
              width: 96vw;
              height: 8vw;
              text-align: left;
              padding-left: 2vw;
              border-radius: 4vw;
              
            }
          }
        }
        .giaoHang{
          margin: 3vw 2vw 0vw;
          .tieuDe{
            text-align: left;
            font-weight: 600;
            line-height: 6vw;
          }
          .giaTriHang{
            line-height: 8vw;
            width: 96vw;
            text-align: left;
          }

        }
        .taoDonHang{
              border-radius: 4vw;
              font-weight: 600;
              width: 96vw;
              color: #fff;
              background-color: #04aa6d;
              margin: 5vw 2vw;
              line-height: 8vw;
        }
        .luuY-ConTainer{
          margin: 5vw 2vw 30vw;
          .tieuDe{
            text-align: left;
            font-weight: 600;
            line-height: 6vw;
          }
          .noiDung{
            text-align: left;
            line-height: 6vw;
            font-style: oblique;
          }
        }
          
          
        
      }
    }
    .pc{
      display: none;
    }
  }
  
}
 @media screen and (min-width : 1024px) {
  .view{
     .mobile{
       display: none;
     }
     .pc{
       .ThemDonHangGiao-ConTaiNer{
     }
   }
   
 }
 }
 


/* Mobile & Tablet */
@media screen and (max-width : 1023px) {
  .view{
    .mobile{
      font-size: 3.5vw;
      .XacNhanTaiKhoan-ConTaiNer{
        .quayLai-tieuDe{
          display: flex;
          font-size: 3.5vw;
          background-color: #fff;
          border-bottom: #d0d4dd 1px solid;
          position: fixed;
      
          top: 0px;
            z-index: 1;
          a{
              text-decoration: none;
          }
          .quayLai{
              color: #04aa6d;
              width: 25vw;
              text-align: left;
              line-height: 9vw;
              padding-left: 2vw;
             
          }
          .tieuDe{
            font-size: 3.9vw;
              font-weight: 600;
              color: red;
              text-align: center;
              width: 75vw;
              line-height: 9vw;
              text-transform: uppercase;
              padding-right: 25vw;
    
          }
      }
      
      .allGiaoDich{
        margin-top: 12vw;
        .giaoDich{
          margin: 2vw;
          border-radius: 3vw;
          border: solid 1px gray;
          .noiDung-sdt-ten{
            display: flex;
            line-height: 8vw;
            .noiDung{
              width: 32vw;
              text-align: left;
              padding-left: 2vw;
            }
            .sdt{
              width: 32vw;
            }
            .soTien{
              width: 32vw;
              text-align: right;
              padding-right: 2vw;
            }
          }
         
          .tgian-soTien-thanhCong{
            display: flex;
            line-height: 8vw;
            .tgian{
              width: 32vw;
              text-align: left;
              padding-left: 2vw;
            }
   
            .ten {
              width: 32vw;
   
          
            }
            .thanhCong{
              width: 32vw;
              text-align: right;
              padding-right: 2vw;
             
            }

          }

        }
      }
      .xacNhan{
        position: fixed;
        z-index: 1;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        overflow: auto;
        background-color: rgba(0,
        0,
        0,
        0.3);
        .xn{
          position: fixed;
          z-index: 9;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-color: #fff;
          // margin: 2vw;
          border-radius: 3vw;
          .ten-sdt-soTien{
            display: flex;
            line-height: 8vw;
            .ten{
              width: 32vw;
              text-align: left;
              padding-left: 2vw;

            }
            .soDu{
              width: 32vw;
          

            }
           
            .dong{
              color: red;
              width: 32vw;
              text-align: right;
              padding-right: 2vw;
              font-weight: 600;
              font-size: 3.9vw;

            }

          }
          .soDu-dong-tc{
            display: flex;
            line-height: 8vw;
            .sdt{
              width: 32vw;
              text-align: left;
              padding-left: 2vw;

            }
           
            .soTien{
              width: 32vw;
            

            }
            .tc{
             color: #04aa6d;
              width: 32vw;
              text-align: right;
              padding-right: 2vw;
              font-weight: 600;

            }
          }

        }
      }
      }
     
    }
    .pc{
      display: none;
    }
  }
  
}

/* PC*/
@media screen and (min-width : 1024px) {
  .view{
    .mobile{
     display: none;
    }
    .pc{
      .XacNhanTaiKhoan-ConTaiNer{
    
      }
    }
  }
}

@media screen and (max-width : 1023px) {
    .view{
       .mobile{
         .UpdateShop-ConTaiNer{
                font-size: 3.5vw;
            .quayLai-tieuDe{
                    display: flex;
                    font-size: 3.5vw;
                    background-color: #fff;
                    border-bottom: #d0d4dd 1px solid;
                    position: fixed;
                
                    top: 0px;
                      z-index: 1;
                    a{
                        text-decoration: none;
                    }
                    .quayLai{
                        color: #04aa6d;
                        width: 25vw;
                        text-align: left;
                        line-height: 9vw;
                        padding-left: 2vw;
                       
                    }
                    .tieuDe{
                      font-size: 3.9vw;
                        font-weight: 600;
                        color: red;
                        text-align: center;
                        width: 75vw;
                        line-height: 9vw;
                        text-transform: uppercase;
                        padding-right: 25vw;
              
                    }
            }
            .updateShop{
                margin-top: 9vw;
                margin-bottom: 20vw;
            
                .allAnhShop-container{
                    .banner-container{
                        margin-bottom: 3vw;
                        .anhPreview-xoaAnh{
                            .anhPreview{
                                height: 75vw;
                                width: 100vw;
                                object-fit: cover;
                                border-radius: 1vw;
                                position: relative;
                            }
                            .xoaAnh{
                                width: 10vw;
                                line-height: 10vw;
                                font-size: 5vw;
                                color: #fff;
                                position: absolute;
                                z-index: 10;
                                top: 9vw;
                                right: 0vw;
                        
                            }
                       
                          
                        }
                        .themAnh{
                            line-height: 75vw;
                            font-size: 15vw;
                            width: 100vw;
                            color: #fff;
                            background-color: #04aa6d;
                            background-image: linear-gradient(0deg, #04aa6d 31%, #ffffff 100%);
                            
                            
                          }
                    }
                    
                    .allAnh-themAnhShop{
                        display: flex;
                        overflow-x:scroll;
                       
                        .allAnh{
                            display: flex;
                      
                           
                            .anh1{
                                width: 20vw;
                                height: 15vw;
                                object-fit: cover;
                                margin-left: 2vw;

                            }
                            .anh2{
                                width: 20vw;
                                height: 11vw;
                                object-fit: cover;
                                margin-bottom: 3vw;
                                margin-top: 2vw;
                                margin-left: 2vw;
                            }

                        }
                        .themAnhShop{
                            
                            .themAnh{
                                width: 20vw;
                                line-height: 11vw;
                                font-size: 10vw;
                                font-weight: 600;
                                background-color: #e5e5e5;
                                margin-left: 2vw;
                                margin-top: 2vw;
                            
                            }

                        }
                    }

                }
                .tenShop-container{
                    margin: 2vw 2vw;
                    .tieuDe{
                        text-align: left;
                        font-weight: 600;
                        line-height: 6vw;
                    }
                    .input{
                        width: 96vw;
                        text-align: left;
                        text-align-last:left ;
                        padding-left: 2vw;
                        height: 8vw;
                        border-radius: 1vw;
                        border: solid 1px gray;
                    }
                    .boxSoanThao{
                        text-align: justify;
                        line-height: 5vw;
                        object-fit: cover;
                    }
                }
                .diaChi{
                    margin: auto 2vw;
                }
                .toaDo-container{
                    margin: 3vw 2vw;
                    .toDo-viTri{
                        display: flex;
                        font-weight: 600;
                        .toaDo{
                            text-align: left;
                            line-height: 8vw;
                            width: 48vw;
                           
                        }
                        .viTri{
                            width: 48vw;
                            color: #04aa6d;
                            text-align: right;
                            line-height: 8vw;
            
                        }
                    }
                  
                    .kDvD-container{
                        display: flex;
                            gap: 2vw;
                            
                        .kinhDo-input{
                      
                           
                            .input{
                                width: 47vw;
                                height: 8vw;
                                border-radius: 1vw;
                                text-align: left;
                                padding-left: 2vw;
                                border: solid 1px gray;
                            }
                           
                        }
                    }
                }
                .nganHang{
                        margin: 3vw 38vw 3vw 2vw;
                        .chonNganHang{
                            text-align: left;
                            line-height: 6vw;
                            font-weight: 600;
            
                        }
                        select, option{
                            text-align: left;
                            padding-left: 2vw;
                            height: 8vw;
                            width: 60vw;
                            border-radius: 1vw;
                            border: solid 1px gray;
                        }
                        .stk-chuTk{
                            .stk{
                                display: block;
                                text-align: left;
                                padding-left: 2vw;
                                height: 8vw;
                                width: 60vw;
                                border-radius: 1vw;
                                margin: 2vw auto;
                            }
                            .chuTk{
                                display: block;
                                text-align: left;
                                padding-left: 2vw;
                                height: 8vw;
                                width: 60vw;
                                border-radius: 1vw;
                            }
                        }
                }
                .soBan-container{
                    margin: 3vw 2vw;
                    .soBan{
                        text-align: left;
                        line-height: 6vw;
                        font-weight: 600;
        
                    }
                   
                    .sdt-them{
                        display: flex;
                        margin: 0vw auto 2vw;
                        gap: 2vw;
                        .sdt{
                            width: 48vw;
                            height: 8vw;
                            border-radius: 1vw;
                            border: solid 1px gray;
                            text-align: left;
                            padding-left: 2vw;
        
                        }
                        .them{
                            width: 10vw;
                            line-height: 8vw;
                            color: #fff;
                            background-color: #04aa6d;
                            border-radius: 1vw;
                            font-weight: 600;
        
                        }
                    }
                    .danhSach-xoa{
                        display: flex;
                        border: solid 1px gray ;
                        border-radius: 1vw;
                        margin: 2vw 36vw auto auto;
                        .danhSach{
                            width: 50vw;
                            line-height: 8vw;
                            text-align: left;
                            padding-left: 1vw;
                            
        
                        }
                        .xoa{   
                            width: 10vw;
                            line-height: 8vw;
                            border-radius: 2vw;
                            color: red;
                            
        
                        }
        
                    }
                 }
                 .xuatBan-container{
                    margin: 3vw 2vw;
                    .tieuDe{
                        text-align: left;
                        font-weight: 600;
                        line-height: 6vw;
                    }
                    .giaoDien-xuatBan{
                        display: flex;
                        gap: 2vw;
                        select, option{
                            text-align: left;
                            height: 8vw;
                            width: 47vw;
                            border-radius: 1vw;
                            border: solid 1px gray;
                            padding-left: 2vw;
                        }
                    }
                 }
                 .admin-container{
                    color: red;
                 .admin{
                    margin: 3vw 2vw;
                    .tieuDe{
                        text-align: left;
                        font-weight: 600;
                        line-height: 6vw;
                    }
                    .input{
                        width: 96vw;
                        text-align: left;
                        text-align-last:left ;
                        padding-left: 2vw;
                        height: 8vw;
                        border-radius: 1vw;
                        border: solid 1px gray;
                    }
                }
                }
                .luu{
                    color: #fff;
                    font-weight: 600;
                    width: 96vw;
                    background-color: #04aa6d;
                    line-height: 8vw;
                    border-radius: 4vw;
                    margin: 10vw 2vw;
                    
                }
                
              
             }
         }
       }
       .pc{
         display: none;
       }
     }
     
   }
    @media screen and (min-width : 1024px) {
     .view{
        .mobile{
          display: none;
        }
        .pc{
          .ViDu-ConTaiNer{
        }
      }
      
    }
    }
    
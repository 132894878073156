@media screen and (max-width : 1023px) {
    .view{
       .mobile{
        .HeaderShop-ConTaiNer{
 
            a{
              text-decoration: none;
              color: black;
            }
            .headerShop{
                display: flex;
                font-size: 3.5vw;
                background-image: linear-gradient(to right, #04aa6d , #fff);
                position: fixed;
                top: 0px;
                z-index: 3;
                .tenShop{
                    font-size: 4.5vw;
                    font-weight: 600;
                    color: #fff;
                    width: 75vw;
                    line-height: 9vw;
                    text-transform: uppercase;
                    text-align: left;
                    padding-left: 2vw;
                    overflow: hidden;
                }
                .caNhan{
                    display: flex;
                    width: 25vw;
                     height: 9vw;
               
                    .xinChao-taiKhoan{
                       width: 18vw;
                        .xinChao{
                            width: 18vw;
                            font-size: 2.8vw;
                            line-height: 4.5vw;
                            font-style: oblique;
                            text-align: right;
                            
                        }
                        
                        .taiKhoan{
                            width: 18vw;
                            font-size: 3vw;
                            line-height: 4.5vw;
                            text-align: right;
                            color: red;
                            overflow: hidden;
                            span{
                                font-weight: 600;
                                line-height: 4.5vw;
                                text-align: center;
                                color: #04aa6d;
                                text-decoration-line:overline;
                                text-decoration-color:red;
                                text-decoration-style:double;
                              }
        
                    
                            }
                    }
                    
                    .avatar{
                            width: 6vw;
                            height: 6vw;
                            border-radius: 50%;
                            border: solid 1px #04aa6d;
                            margin: 1.5vw 0.5vw;
                    }
                }
                
            }
            .banner-tenShop{
              margin: 9vw 0vw auto;
              .banner-tien-lui{
                position: relative;
          
                .banner{
                    width: 100%;
                    height: 75vw;
                    object-fit: cover;
                }
                .themAnh{
                  line-height: 75vw;
                  font-size: 15vw;
                  width: 100vw;
                  color: #fff;
                  background-color: #04aa6d;
                  background-image: linear-gradient(0deg, #04aa6d 31%, #ffffff 100%);
 
                }
                .lui{
                  opacity: 0.6;
                  position: absolute;
                  z-index: 2;
                  top: 23vw;
                  left: 0vw;
                  line-height: 29vw;
                  font-size: 5vw;
                  font-weight: 600;
                  width: 15vw;
                  color: #e5e5e5;
                  text-align: left;
                  padding-left: 2vw;
                }
                .tien{
                  opacity: 0.6;
                  position: absolute;
                  z-index: 2;
                  top: 23vw;
                  right: 0vw;
                  line-height: 29vw;
                  font-size: 5vw;
                  font-weight: 600;
                  width: 15vw;
                  color: #e5e5e5;
                  text-align: right;
                  padding-right: 2vw;
               
                }
                .dauCham2{
                  display: flex;
                  position: absolute;
                  z-index: 2;
                  bottom: 2vw;
                  left: 45vw;
                  .chamTo{
                    font-size: 2.5vw;
                    line-height: 10vw;
                    color: #fff;
                    width: 5vw;
                  }
                  .chamNho{
                    font-size: 1.5vw;
                    line-height: 10vw;
                    color: #e5e5e5;
                    width: 5vw;
             
                  }
                }
                .dauCham3{
                  display: flex;
                  position: absolute;
                  z-index: 2;
                  bottom: 2vw;
                  left: 41vw;
                  .chamTo{
                    font-size: 2.5vw;
                    line-height: 10vw;
                    color: #fff;
                    width: 6vw;
                  }
                  .chamNho{
                    font-size: 1.5vw;
                    line-height: 10vw;
                    color: #e5e5e5;
                    width: 6vw;
             
                  }
                }  
                .dauCham4{
                  display: flex;
                  position: absolute;
                  z-index: 2;
                  bottom: 2vw;
                  left: 38vw;
                  .chamTo{
                    font-size: 2.5vw;
                    line-height: 10vw;
                    color: #fff;
                    width: 6vw;
                  }
                  .chamNho{
                    font-size: 1.5vw;
                    line-height: 10vw;
                    color: #e5e5e5;
                    width: 6vw;
             
                  }
                }  
                .dauCham5{
                  display: flex;
                  position: absolute;
                  z-index: 2;
                  bottom: 2vw;
                  left: 35vw;
                  .chamTo{
                    font-size: 2.5vw;
                    line-height: 10vw;
                    color: #fff;
                    width: 6vw;
                  }
                  .chamNho{
                    font-size: 1.5vw;
                    line-height: 10vw;
                    color: #e5e5e5;
                    width: 6vw;
                  }
                }    
               
              }
                .phimChucNang{
                    display: flex;
                    margin: 6vw auto 3vw;
                    padding: auto 2vw;
                    gap: 2vw;
                    overflow-x:scroll;
                    .phim1-phim2{
                        .icon-follow{
                        width: 25vw;
                        margin-bottom: 2vw;
                        display: inline-block;
                        text-decoration: none;
                        .icon{
                            text-align: center;
                            margin: auto 7.5vw;
                            width: 10vw;
                            height: 10vw;
                            border-radius: 2vw;
                            border: solid 1px gray;
                            color: red;
                            line-height: 10vw;
                            font-size: 5vw;
                            font-weight: 600;
                          
        
                        }
                        .follow{
                            font-size: 3vw;
                            line-height: 6vw;
                            text-align: center;
        
                        }
        
                        }
                    }
                   
                }
            }   
          }
       
         }
       }
       .pc{
         display: none;
       }
     }
     
   
@media screen and (min-width : 1024px) {
     .view{
        .mobile{
          display: none;
        }
        .pc{
          .HeaderShop-ConTaiNer{
        }
      }
      
    }
    }
    
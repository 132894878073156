@media screen and (max-width : 1023px) {
    .view{
       .mobile{
        .DoiTac{
   
            font-size: 3.5vw;
            .quayLai-tieuDe{
              display: flex;
              font-size: 3.5vw;
              background-color: #fff;
              border-bottom: #d0d4dd 1px solid;
              position: fixed;
          
              top: 0px;
                z-index: 1;
              a{
                  text-decoration: none;
              }
              .quayLai{
                  color: #04aa6d;
                  width: 25vw;
                  text-align: left;
                  line-height: 9vw;
                  padding-left: 2vw;
                 
              }
              .tieuDe{
                font-size: 3.9vw;
                  font-weight: 600;
                  color: red;
                  text-align: center;
                  width: 75vw;
                  line-height: 9vw;
                  text-transform: uppercase;
                  padding-right: 25vw;
        
              }
          }
            .nhanVien-container1{
              margin: 12vw 2vw 5vw;
              .nhanVien{
                  text-align: left;
                  font-weight: 600;
                  line-height: 5vw;
        
              }
              .sdt-them{
                display: flex;
                margin: 2vw auto;
                gap: 2vw;
                .sdt{
                    width: 40.5vw;
                    height: 8vw;
                    border-radius: 1vw;
                    border: solid 1px gray;
                    text-align: center;
        
                }
                .them{
                    width: 10vw;
                    line-height: 8vw;
                    color: #fff;
                    background-color: #04aa6d;
                    border-radius: 2vw;
                    font-weight: 600;
        
                }
            }
              .danhSach-xoa{
                  display: flex;
                  margin: 2vw auto;
                  border: solid 1px #d0d4dd ;
                  border-radius: 2vw;
                  .danhSach{
                      width: 42.5vw;
                      line-height: 8vw;
                      text-align: center;
        
                  }
                  .xoa{   
                      width: 10vw;
                      line-height: 8vw;
                      font-weight: 600;
                      color: red;
                      border-radius: 2vw;
                      
        
                  }
        
              }
              
           }
           .nhanVien-container{
            margin: 5vw 2vw;
            .nhanVien{
                text-align: left;
                font-weight: 600;
                line-height: 5vw;
        
            }
            .sdt-them{
              display: flex;
              margin: 2vw auto;
              gap: 2vw;
              .sdt{
                  width: 40.5vw;
                  height: 8vw;
                  border-radius: 1vw;
                  border: solid 1px gray;
                  text-align: center;
        
              }
              .them{
                  width: 10vw;
                  line-height: 8vw;
                  color: #fff;
                  background-color: #04aa6d;
                  border-radius: 2vw;
                  font-weight: 600;
        
              }
          }
            .danhSach-xoa{
                display: flex;
                margin: 2vw auto;
                border: solid 1px #d0d4dd ;
                border-radius: 2vw;
                .danhSach{
                    width: 42.5vw;
                    line-height: 8vw;
                    text-align: center;
                    
        
                }
                .xoa{   
                    width: 10vw;
                    line-height: 8vw;
                    font-weight: 600;
                    color: red;
                    border-radius: 2vw;
                    
        
                }
        
            }
            
         }
           
           }
       }
       .pc{
         display: none;
       }
     }
     
   }
    @media screen and (min-width : 1024px) {
     .view{
        .mobile{
          display: none;
        }
        .pc{
          .ViDu-ConTaiNer{
        }
      }
      
    }
    }
    
@media screen and (max-width : 1023px) {
 .view{
    .mobile{
      .DonHangShopChiTiet-ConTaiNer{
        a{text-decoration: none};
        font-size: 3.5vw;
        .quayLai-tieuDe{
          display: flex;
          font-size: 3.5vw;
          background-color: #fff;
          border-bottom: #d0d4dd 1px solid;
          position: fixed;
      
          top: 0px;
            z-index: 1;
          a{
              text-decoration: none;
          }
          .quayLai{
              color: #04aa6d;
              width: 25vw;
              text-align: left;
              line-height: 9vw;
              padding-left: 2vw;
             
          }
          .tieuDe{
            font-size: 3.9vw;
              font-weight: 600;
              color: red;
              text-align-last: center;
              width: 66vw;
              line-height: 9vw;
              text-transform: uppercase;
              padding-right: 16vw;
             
          }
          .inHoaDon{
            width: 9vw;
            text-align: center;
            line-height: 9vw;
            font-size: 5vw;
           
          }
      }
      .trangThaiDh-huyDon{
        margin:12vw 2vw auto;
        font-weight: 600;
        display: flex;
        .trangThaiDh{
          text-align: left;
          line-height: 6vw;
          width: 80vw;
        }
        .huyDon{
          text-align: right;
          line-height: 6vw;
          width: 16vw;
          color: #04aa6d;
        }
       
      }
      
      .sanPham-container{
        margin-top:2vw;
  
      .allSanPham{
        margin: 0vw 2vw;
        .sanPham{
          margin-bottom: 1vw;
          .tenSanPham{
            width: 96vw;
            overflow: auto;
            text-align: left;
            font-weight: 600;
            color: red;
            line-height: 5vw;
        
          }
          .dacDiem-themGioHang{
            display: flex;
            margin-bottom: 1vw;
            .tenDD-gioPhut{
              width: 20vw;
            .tenDD{
              text-align: left;
              font-weight: 600;
              line-height: 6vw;
              overflow: auto;
              height: 6vw;
            }
            .gioPhut{
              text-align: left;
              line-height: 6vw;
              // color: red;
            }
          }
        .giaSanPham{
          width: 20vw;
          .giaNY{
            text-decoration: line-through;
            line-height: 6vw;
            overflow: auto;
          }
          .giaKM{
            font-weight: 600;
            line-height: 6vw;
            overflow: auto;
          }
         
        }
        .soLuong-SL{
          width: 16vw;
          .soLuong{
            line-height: 6vw;
          }
          .SLMua{
            font-weight: 600;
            line-height: 6vw;
            overflow: auto;
          }
  
        }
        .thanhTien-TT{
          width: 20vw;
          .thanhTien{
            text-align: center;
            line-height: 6vw;
          }
          .TT{
            text-align: center;
            font-weight: 600;
            line-height: 6vw;
            overflow: auto;
          }
        }
        .xacNhan-boxCheck{
          width: 20vw;
          text-align: center;
          .xacNhan{
            line-height: 6vw;
          }
          .daXong{
            width: 5vw;
            margin: 0.5vw 7.5vw;
            font-size: 4vw;
            line-height: 5vw;
            background-color: #04aa6d;
            color: #fff;
          }
          .chuaXong{
            width: 5vw;
            margin: 0.5vw 7.5vw;
            font-size: 4vw;
            line-height: 5vw;
            background-color: #e5e5e5;
          }
        }
       
        
      }
        }
    }
    }
    .tinhTien-container{
      margin: 2vw;
      .tieuDe{
        text-align: left;
        font-weight: 600;
        line-height: 6vw;
      }

      .tongTien{
        display: flex;
     
        .tieude{
          width: 51vw;
              line-height: 6vw;
              text-align: left;
          
        }
        .sotien{
          width: 30vw;
          line-height: 6vw;
          
        }
        .giamTru{
          width: 30vw;
          border-radius: 3vw;
          line-height: 6vw;
        }
        .ok{
          border: solid 1px #04aa6d;
          border-radius: 1vw;
          width: 9vw;
          margin: 0.5vw auto auto 1vw;
          font-size: 4vw;
          line-height: 5vw;
          color: #04aa6d;
        }
      }
    
      .ptThanhToan{
        color: #04aa6d;
        width: 96vw;
        text-align: left;
        font-weight: 600;
        line-height: 6vw;
        margin-top: 2vw;
        .tienMat{

        }
        .chuyenKhoan{
          .tieuDe{

          }
          .qr{
            width: 96vw;
            object-fit: cover;
          }
        }
      }
    
    }
    .nguoiNhan-container{
      margin: 2vw;
      .tieuDe{
        text-align: left;
        font-weight: 600;
        line-height: 6vw;
      }
      .thongTinNguoiNhan{
        .thongTinChiTiet{
          .noiDung{
            line-height: 6vw;
            width: 96vw;
            text-align: left;
            display: block;
            color: black;
          }
        }
      }
    }
    .thueShip{
      text-align: left;
      padding-left: 2vw;
      color: #04aa6d;
      font-weight: 600;
    }
    .thongTinShip{
      margin: 2vw;
      .noiGui{
        width: 96vw;
        text-align: left;
        display: block;
        color: black;
        line-height: 6vw;
      }
      .phiShip-input{
        display: flex;
        gap: 2vw;
        .phiShip{
          text-align: left;
          line-height: 6vw;
        }
        .input{
          border-radius: 3vw;
          line-height: 6vw;
        }
        .ok{
          border: solid 1px #04aa6d;
          border-radius: 1vw;
          width: 9vw;
          margin: 0.5vw auto auto 1vw;
          font-size: 4vw;
          line-height: 5vw;
          color: #04aa6d;
        }
       
       
       
      }
      .luuY{
        width: 96vw;
        text-align: left;
        font-style:italic;
        color: gray;
        line-height: 6vw;
      }
    }
    .thongTinShip-container{
      margin: 2vw;
      .tieuDe{
        text-align: left;
        font-weight: 600;
        line-height: 6vw;
      }
      .noiDung{
        line-height: 6vw;
        width: 96vw;
        text-align: left;
        display: block;
        color: black;
        text-decoration: none;
      }
      .phiShip-input{
        display: flex;
        gap: 1vw;
        .phiShip{
          line-height: 6vw;
        }
        .input{
          border-radius: 3vw;
          border: solid 1px gray;
          width: 28vw;
          text-align: center;
          line-height: 6vw;
        }
      }
    }
    .phimChucNang-container{
      margin: 10vw 2vw 30vw;
      font-size: 3.5vw;
      color: #fff;
      font-weight: 600;
    
        .phimChucNang{
          .phim{
            cursor: pointer;
            border-radius: 4vw;
            width: 96vw;
            background-color: #04aa6d;
            line-height: 8vw;
          }
        }
        

  
    }
    .viewSanPhamXong{
      position: fixed;
      z-index: 1;
      left: 0;
      top: 0;
      width: 100vw;
      height: 100vh;
      overflow: auto;
      background-color: rgba(0,
              0,
              0,
              0.5);
              justify-content: center;
              align-items: center;
              .sanPham-phimChucNang{
                background-color: #fff;
                position: fixed;
                z-index: 9;
                width: 96vw;
                max-height: 60vh;
                overflow: auto;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                border-radius: 3vw;
                padding: 2vw 2vw 0vw;
                .sanPham{
                  display: flex;
                  font-size: 5vw;
                  .tenSanPham{
                    line-height: 8vw;
                    width: 60vw;
                    text-align: left;
                    font-weight: 600;
              
  
                  }
                  .dongLai{
                    line-height: 8vw;
                    width: 32vw;
                    text-align: right;
                    color: red;
                  }
               
                }
                .tenDacDiem-tonKho-soLuong{
                  display: flex;
                  margin-top: 2vw;
                  .tenDacDiem{
                    line-height: 10vw;
                    width: 30vw;
                    text-align: left;
                  }
                  .tonKho{
                    width: 30vw;
                    line-height: 5vw;
                  }
                  .soLuong-SL{
                    width: 30vw;
                    .soLuong{
                      line-height: 5vw;
                    }
                    .thayDoiSl{
                      display: flex;
                      .giamSl{
                        width: 4.5vw;
                        color: #04aa6d;
                        font-size: 3.9vw;
                        // font-weight: 600;
                        line-height: 5vw;
                        margin: auto 0.5vw;
                   
            
                      }
                      .SL{
                        width: 19vw;
                        height: 5vw;
                        text-align: center;
                        border-radius: 2.5vw;
                        border: solid 1px gray;
                        color: red;
                        overflow: auto;
              
                      }
                      input::placeholder {
                        color: red;
                        overflow: auto;
                      }
                      .tangSl{
                 
                      }
                    }
                    .SLMua{
                      color: red;
                      line-height: 5vw;
                      overflow: auto;
                    }
            
                  }
                 
                
                
                }
                .daXong-chuaXong{
                  .daXong{
                    line-height: 8vw;
                    margin: 5vw 0vw;
                    width: 92vw;
                    background-color: #04aa6d;
                    color: #fff;
                    border-radius: 4vw;
                    font-weight: 600;
  
                  }
                  .chuaXong{
                    line-height: 8vw;
                    margin: 5vw 0vw;
                    width: 92vw;
                    background-color: red;
                    color: #fff;
                    border-radius: 4vw;
                    font-weight: 600;
                  }
                }
              }
    
    }
      }
    }
    .pc{
      display: none;
    }
  }
  
}
 @media screen and (min-width : 1024px) {
  .view{
     .mobile{
       display: none;
     }
     .pc{
       .DonHangShopChiTiet-ConTaiNer{
     }
   }
   
 }
 }
 
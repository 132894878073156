

/* Mobile & Tablet */
@media screen and (max-width : 1023px) {
  .view{
    .mobile{
      
      .chiTietDonHang-ConTaiNer{
        font-size: 3.5vw;
        margin-bottom: 30vw;
        .quayLai-tieuDe{
          width: 100vw;
          display: flex;
          font-size: 3.5vw;
          background-color: #fff;
          border-bottom: #d0d4dd 1px solid;
          position: fixed;
          top: 0px;
            z-index: 1;
          a{
              text-decoration: none;
          }
          .quayLai{
              color: #04aa6d;
              width: 25vw;
              text-align: left;
              line-height: 9vw;
              padding-left: 2vw;
             
          }
          .tieuDe{
            font-size: 3.9vw;
              font-weight: 600;
              color: red;
              text-align-last: center;
              width: 66vw;
              line-height: 9vw;
              text-transform: uppercase;
              padding-right: 16vw;
             
          }
          .inHoaDon{
            width: 9vw;
            text-align: center;
            line-height: 9vw;
            font-size: 5vw;
           
          }

         
        }
        .trangThaiDh-huyDon{
          margin:12vw 2vw auto;
          font-weight: 600;
          display: flex;
          .trangThaiDh{
            text-align: left;
            line-height: 6vw;
            width: 60vw;
            overflow: hidden;
          }
          .huyDon{
            text-align: right;
            line-height: 6vw;
            width: 36vw;
            color: #04aa6d;
          }
         
        }
        .chiTiet{
          margin: 2vw 2vw auto;  
          .noiGui-thongTin{
            margin: 2vw auto 0vw;
            .tieuDe{
              width: 96vw;
              line-height: 6vw;
              font-weight: 600;
              
              text-align: left;
     

            }
            .noiDung{
              display: block;
              width: 96vw;
              text-align: left;
              line-height: 6vw;
              padding-left: 2vw;
              text-decoration: none;
              color: #000;
            }
            .phiShip{
              width: 96vw;
              text-align: left;
              line-height: 6vw;
            }
            .soTien{
              width: 96vw;
              text-align: left;
              line-height: 6vw;
              padding-left: 2vw;
              color: red;
            }
          }
        }
        .thanhToan-container{
          margin: 2vw;
          .tieuDe{
            width: 96vw;
            line-height: 6vw;
            font-weight: 600;
            text-align: left;
          }
          .noiDung{
            width: 96vw;
            text-align: left;
            line-height: 6vw;
            padding-left: 2vw;
          }
          .soTien{
            width: 96vw;
            text-align: left;
            line-height: 6vw;
            padding-left: 2vw;
            color: red;
          }
          .qr{
            object-fit: cover;
            width: 68vw;
          }
        }
        .phimChucNang-container{
          margin: 5vw 2vw 0vw;
          font-weight: 600;
            .phimChucNang{
              .phim{
                border-radius: 4vw;
                width: 96vw;
                color: #fff;
                background-color: #04aa6d;
                line-height: 8vw;
              }
              .phimXam{
                width: 96vw;
                font-size: 3.9vw;
                line-height: 8vw;
                color: gray;
              }
              .phimXanh{
                font-size: 3.9vw;
                width: 96vw;
                color: #04aa6d;
                line-height: 8vw;
              }
              .phimDo{
                font-size: 3.9vw;
                width: 96vw;
                color: red;
                line-height: 8vw;
              }
              .xacNhan{
                color: red;
              }
             
            }
            
    
      
        }
        .luuY-ConTainer{
          margin: 5vw 2vw 30vw;
          .luuY{
            .tieuDe{
              text-align: left;
              font-weight: 600;
              line-height: 6vw;
            }
            .noiDung{
              text-align: left;
              line-height: 6vw;
              font-style: oblique;
            }
          }
        
        }
      }
    
    }
    .pc{
      display: none;
    }
  }
  
}

/* PC*/
@media screen and (min-width : 1024px) {
  .view{
    .mobile{
     display: none;
    }
    .pc{
      .TimGiaoHang-ConTaiNer{
    
      }
    }
  }
}

/* Mobile & Tablet */
@media screen and (max-width : 1023px) {
    .chiTietDonHangShop-Container{
      a{text-decoration: none};
      font-size: 3.5vw;
      .quayLai-tieuDe{
        display: flex;
        font-size: 3.5vw;
        background-color: #fff;
        border-bottom: #d0d4dd 1px solid;
        position: fixed;
    
        top: 0px;
          z-index: 1;
        a{
            text-decoration: none;
        }
        .quayLai{
            color: #04aa6d;
            width: 25vw;
            text-align: left;
            line-height: 9vw;
            padding-left: 2vw;
           
        }
        .tieuDe{
          font-size: 3.9vw;
            font-weight: 600;
            color: red;
            text-align-last: center;
            width: 66vw;
            line-height: 9vw;
            text-transform: uppercase;
            padding-right: 16vw;
           
        }
        .inHoaDon{
          width: 9vw;
          text-align: center;
          line-height: 9vw;
          font-size: 5vw;
         
        }
    }
    .sanPham-container{
        margin-top:12vw;
  
      .allSanPham{
        margin: 0vw 2vw;
        .sanPham{
          margin-bottom: 1vw;
          .tenSanPham{
            width: 96vw;
            overflow: auto;
            text-align: left;
            font-weight: 600;
            color: red;
            line-height: 5vw;
        
          }
          .dacDiem-themGioHang{
            display: flex;
            margin-bottom: 1vw;
            .tenDD-gioPhut{
              width: 22vw;
            .tenDD{
              text-align: left;
              font-weight: 600;
              line-height: 6vw;
              overflow: auto;
              height: 6vw;
            }
            .gioPhut{
              text-align: left;
              line-height: 6vw;
              color: red;
            }
          }
        .giaSanPham{
          width: 22vw;
          .giaNY{
            text-decoration: line-through;
            line-height: 6vw;
            overflow: auto;
            text-align: left;
          }
          .giaKM{
            font-weight: 600;
            color: red;
            line-height: 6vw;
            overflow: auto;
            text-align: left;
          }
         
        }
        .soLuong-SL{
          width: 25vw;
          .soLuong{
            line-height: 6vw;
          }
          .thayDoiSl{
            display: flex;
            .giamSl{
              width: 4.5vw;
              color: #04aa6d;
              font-size: 3.9vw;
              font-weight: 600;
              line-height: 6vw;
              margin: auto 0.5vw;
         
  
            }
            .SL{
              width: 14vw;
              height: 6vw;
              text-align: center;
              font-weight: 600;
              border-radius: 2vw;
              border: solid 1px gray;
              color: red;
              overflow: auto;
    
            }
            input::placeholder {
              font-weight: 600;
              color: red;
              overflow: auto;
            }
            .tangSl{
       
            }
          }
          .SLMua{
            font-weight: 600;
            color: red;
            line-height: 6vw;
            overflow: auto;
          }
  
        }
        .thanhTien-TT{
          width: 22vw;
          .thanhTien{
            text-align: center;
            line-height: 6vw;
          }
          .TT{
            text-align: center;
            font-weight: 600;
            line-height: 6vw;
            overflow: auto;
            color: red;
          }
        }
        .daXong{
          width: 6vw;
          font-size: 4vw;
          line-height: 6vw;
          margin: 3vw auto;
          background-color: #04aa6d;
          color: #fff;
          font-weight: 600;
          border-radius: 1vw;
          
        }
        .chuaXong{
          width: 6vw;
          font-size: 4vw;
          line-height: 6vw;
          margin: 3vw auto;
          background-color: #e5e5e5;
          border-radius: 1vw;
          font-weight: 600;
    
        }
        
      }
        }
    }
    }
    .tinhTien-container{
      margin: 3vw 2vw;
      font-size: 3.5vw;

      .tieuDe{
        text-align: left;
        font-weight: 600;
        line-height: 8vw;
        color: #04aa6d;
      }

      .tongTien{
        display: flex;
        .tieude{
          width: 62vw;
              line-height: 6vw;
              text-align: left;
          
        }
        .sotien{
          width: 34vw;
          text-align: center;
          line-height: 6vw;
          height: 6vw;
          border-radius: 3vw;
        }
      }
      .qr{
        width: 68vw;
        object-fit: cover;
      }
    
    }
    .nguoiNhan-container{
      margin: 3vw 2vw;
      font-size: 3.5vw;
      .tieuDe{
        text-align: left;
        font-weight: 600;
        line-height: 8vw;
        color: #04aa6d;
      }
      .thongTinNguoiNhan{
        .thongTinChiTiet{
          .noiDung{
            line-height: 6vw;
            width: 96vw;
            text-align: left;
            display: block;
            color: black;
          }
        }
      }
    }
    .phuongThucGiaoHang-container{
      margin: 3vw 2vw;
      font-size: 3.5vw;
      .chonPT{
        display: flex;
        .tieuDe{
          width: 48vw;
          text-align: left;
          font-weight: 600;
          line-height: 8vw;
          color: #04aa6d;
        }
        .chonPhuongThuc{
          width: 48vw;
          text-align-last:center ;
          height: 8vw;
          font-weight: 600;
          border-radius: 2vw;
          color: #04aa6d;
          border: solid 1px gray;
        }
      }
      .thongTinShip{
        line-height: 6vw;
        .noiGui{
          width: 96vw;
          text-align: left;
        }
        .phiShip-input{
          display: flex;
       
          .phiShip{
            width: 48vw;
            text-align: left;
            line-height: 8vw;
          }
          .input{
            width: 48vw;
            color: red;
            font-size: 3.9vw;
        font-weight: 600;
        border: solid 1px gray;
        border-radius: 2vw;
        line-height: 8vw;
          }
         
         
         
        }
        .banPhimSo{
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr; 
          gap:2vw;
          color: #fff;
          font-weight: 600;
          font-size: 3.9vw;
          margin-top: 3vw;
          .giaTriTien{
              background-color: #04aa6d;
              padding: 1.5vw 0.5vw ;
              border-radius: 1vw;
          }
        
        }
      }
     
    }
    .thongTinShip-container{
      margin: 3vw 2vw;
      font-size: 3.5vw;
      .tieuDe{
        text-align: left;
        font-weight: 600;
        line-height: 8vw;
        color: #04aa6d;
      }
      .noiDung{
        line-height: 6vw;
        width: 96vw;
        text-align: left;
        display: block;
        color: black;
      }
    }
    .phimChucNang-container{
      margin: 10vw 2vw 30vw;
      font-size: 3.5vw;
      color: #fff;
      font-weight: 600;
    
        .phimChucNang{
          .phim{
            cursor: pointer;
            border-radius: 1vw;
            width: 96vw;
            background-color: #04aa6d;
            padding: 1vw;
            margin: 5vw 0vw;
            line-height: 8vw;
          }
          .phim2{
            text-align: center;
            color:#04aa6d;
            border: solid 1px #04aa6d;
            border-radius: 2vw;
            width: 39vw;
            line-height: 6vw;
          }
        }
        

  
    }
    
    }
}

/* PC*/
@media screen and (min-width : 1024px) {
    // .chiTietDonHang-Container{
    //     a{text-decoration: none};
    //     margin-top: 5vw;
    //     padding-top: 3vw;
    //     padding-bottom: 20vw;
     
       
    // }
}
@media print {
//   .chiTietDonHang-Container{
//     .quayLai-tieuDe{
//       display: none;
//       .quayLai{
//         display: none;

//       }
//       .tieuDe{
//         display: none;

//       }
//   }
//  .tieuDeDonHang{
//   display: none;
    
//  }
//  .sanPham{
//   display: none;
//   .tenSanPham-xoa{
//     .tenSanPham{
//       display: none;

//      }
   
//   }

// .dacDiem-themGioHang{
//   display: none;
// .anhSp-tenSp{
//   display: none;
//   .tenSp{
//     display: none;
//   }
//   .gioPhut{
//     display: none;
//   }
// }
// .giaSanPham{
//   display: none;
//   .giaKM{
//     display: none;
//   }
//   .giaNY-giamGia{
//     display: none;
//     .giaNY{
//       display: none;

//     }
//     .giamGia{
//       display: none;

//     }
//   }
 
// }
// .soLuong-SL{
//   display: none;
//   .soLuong{
//     display: none;
//   }
//   .SL{
//     display: none;

//   }

// }
// .thanhTien-TT{
//   display: none;
 
//   .thanhTien{
//     display: none;
//   }
//   .TT{
//     display: none;
//   }
// }

// }
// }
// .tinhTien{
//   display: none;
//   .tongTien{
//     display: none;
//     .tieude{
//       display: none;
//     }
//     .sotien{
//       display: none;
//     }
//   }

// }
// .thanhToanQrCode-container{
//   display: none;
//   .thanhToanQr{
//     display: none;
//   }
//   .qr{
//     display: none;
//   } 
// }
// .phancach{
//   display: none;
//   }
//   .thongTinChiTiet{
//     display: none;
//     .tieuDe{
//       display: none;
    

//     }
//     .noiDung{
//       display: none;

//     }
//   }
//   .tongKet{
//     display: none;

//     .hoanThanh{
//       display: none;
//   }
//   .huyDon{
//     display: none;
//   }
//   .inHoaDon{
//     display: none;
//   }
//   }
// }
  

    
}



/* Mobile & Tablet */
// @media screen and (max-width : 1023px) {
//   .XemAnh2-ConTaiNer{
//     .anhBanDau{
      
//     }
//     .anhFullMan{
//     background-color: black;
//     width: 100vw;
//     height: 100vh;
//     position: fixed;
//       z-index: 10;
//       left: 0;
//       top: 0;
//   .close{
//     width: 10vw;
//     height: 10vw;
//     line-height: 10vw;
//     font-size: 6vw;
//     border-radius: 50%;
//     padding: 3vw auto;
//     font-weight: 500;
//     color: #fff;
//     cursor: pointer;
//     display: block;

//   }
//     .xemAnh{
//       top: 50%;
//       left: 50%;
//       position: absolute;
//       transform: translate(-50%, -50%);
     
//       .anh{
//         width: 100vw;
  
//       }

//     }
  

//     }
//   }
// }

/* PC*/
// @media screen and (min-width : 1024px) {
    // .XemAnh2-ConTaiNer{
    //   background-color: black;
    //   width: 100vw;
    //   height: 100vh;
    //   position: fixed;
    //     z-index: 10;
    //     left: 0;
    //     top: 0;
    //   .close{
    //     width: 5vw;
    //     height: 5vw;
    //     font-size: 3vw;
    //     color: #fff;
    //   }
    //     .xemAnh{
    //       top: 50%;
    //       left: 50%;
    //       position: absolute;
    //       transform: translate(-50%, -50%);
         
    //       .anh{
    //         height: 80vh;
      
    //       }
    
    //     }
      
    // }


    // .XemAnh2-ConTaiNer{
    //   .anhBanDau{
        
    //   }
    //   .anhFullMan{
    //   background-color: black;
    //   width: 100vw;
    //   height: 100vh;
    //   position: fixed;
    //     z-index: 10;
    //     left: 0;
    //     top: 0;
    // .close{
    //   width: 10vw;
    //   height: 10vw;
    //   line-height: 10vw;
    //   font-size: 6vw;
    //   border-radius: 50%;
    //   padding: 3vw auto;
    //   font-weight: 500;
    //   color: #fff;
    //   cursor: pointer;
    //   display: block;
  
    // }
    //   .xemAnh{
    //     top: 50%;
    //     left: 50%;
    //     position: absolute;
    //     transform: translate(-50%, -50%);
       
    //     .anh{
    //       width: 100vw;
    
    //     }
  
    //   }
    
  
    //   }
    // }
// }



@media screen and (max-width : 1023px) {
  .view{
     .mobile{
      .XemAnh2-ConTaiNer{
        .anhBanDau{
          
        }
        .anhFullMan{
        background-color: black;
        width: 100vw;
        height: 100vh;
        position: fixed;
          z-index: 10;
          left: 0;
          top: 0;
      .close{
        width: 10vw;
        height: 10vw;
        line-height: 10vw;
        font-size: 6vw;
        border-radius: 50%;
        padding: 3vw auto;
        font-weight: 500;
        color: #fff;
        cursor: pointer;
        display: block;
    
      }
        .xemAnh{
          top: 50%;
          left: 50%;
          position: absolute;
          transform: translate(-50%, -50%);
         
          .anh{
            width: 100vw;
      
          }
    
        }
      
    
        }
      }
     }
     .pc{
       display: none;
     }
   }
   
 }
  @media screen and (min-width : 1024px) {
   .view{
      .mobile{
        display: none;
      }
      .pc{
        .XemAnh2-ConTaiNer{
          .anhBanDau{
            
          }
          .anhFullMan{
            background-color: black;
            width: 100vw;
            height: 100vh;
            position: fixed;
              z-index: 10;
              left: 0;
              top: 0;
            .close{
              width: 5vw;
              height: 5vw;
              font-size: 3vw;
              color: #fff;
              display: block;
            }
              .xemAnh{
                top: 50%;
                left: 50%;
                position: absolute;
                transform: translate(-50%, -50%);
               
                .anh{
                  height: 80vh;
            
                }
          
              }
            
          }
        }

        
    }
    
  }
  }
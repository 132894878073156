@media screen and (max-width : 1023px) {
  .view{
     .mobile{
       .GioHang-ConTaiNer{
        margin-bottom: 30vw;
        font-size: 3.5vw;
    
        .quayLai-tieuDe{
          display: flex;
          background-color: #fff;
          border-bottom: #d0d4dd 1px solid;
          position: fixed;
          top: 0px;
          z-index: 1;
          a{text-decoration: none}
        .quayLai{
          color: #04aa6d;
          width: 25vw;
          text-align: left;
          line-height: 9vw;
          padding-left: 2vw;
         
      }
      .tieuDe{
          font-size: 3.9vw;
          font-weight: 600;
          color: red;
          text-align: center;
          width: 75vw;
          line-height: 9vw;
          text-transform: uppercase;
          padding-right: 25vw;

      }
        }
        .thongTinGioHang-container{
          padding: 9vw 2vw 2vw;
          background-color: #e5e5e5;
        .sanPham{
          border: solid 1px gray;
          background-color: #fff;
          border-radius: 2vw;
          margin-top: 2vw;
        .tenSanPham-xoa{
          display: flex;
          font-weight: 600;
          margin-bottom: 1vw;
          .tenSanPham{
            width: 92vw;
            text-align: left;
            color: red;
            line-height: 8vw;
            padding-left: 2vw;
      
           }
          .xoa{
            line-height: 8vw;
            font-size: 3.9vw;
            width: 8vw;
            text-align: right;
            padding-right: 2vw;
            
          }
        }
        .dacDiem-tenDacDiem{
          margin-bottom: 2vw;
          .dacDiem-themGioHang{
            display: flex;
            // .anhSp{
            //     margin-left: 2vw;
            //     width: 12vw;
            //     height: 15vw;
            //     object-fit: cover;
            //     border-radius: 1vw;
            // }
            .giaSanPham{
              width: 29vw;
              .giaNY{
                line-height: 7.5vw;
                text-align: center;
                text-decoration: line-through;
                overflow: auto;
               }
              .giaKM{
                line-height: 7.5vw;
                font-weight: 600;
                color: red;
                overflow: auto;
              }
           
             
            }
            .soLuong-SL{
              width: 30vw;
              .soLuong{
                line-height: 7.5vw;
                text-align: center;
                width: 30vw;
      
              }
              .thayDoiSl{
                display: flex;
                .giamSl{
                  width: 5.5vw;
                  color: #04aa6d;
                  font-size: 3.9vw;
                  font-weight: 600;
                  line-height: 7.5vw;
                  margin: auto 1vw;
             
      
                }
                .SL{
                  width: 15vw;
                  height: 7.5vw;
                  text-align: center;
                  font-weight: 600;
                  border-radius: 3.75vw;
                  border: solid 1px gray;
                  color: red;
                  overflow: auto;
        
                }
                input::placeholder {
                  font-weight: 600;
                  color: red;
                  overflow: auto;
                }
                .tangSl{
           
                }
              }
              
      
            }
            .thanhTien-TT{
              width: 23vw;
              .thanhTien{
                line-height: 7.5vw;
                text-align: center;
              }
              .TT{
                line-height: 7.5vw;
                text-align: center;
                font-weight: 600;
                overflow: auto;
                color: red;
      
              }
            }
            
          }
        .tenDacDiem{
            font-weight: 600;
            line-height: 6vw;
            text-align: left;
            padding-left: 2vw;
        }
        }
        }
        .tongTien{
        display: flex;
        border: solid 1px gray;
        background-color: #fff;
        border-radius: 4vw;
        margin-top: 2vw;
        font-weight: 600;
        color: red;
        width: 96vw;
        .tieude{
          width: 73vw;
          text-align: center;
          text-transform: uppercase;
          line-height: 8vw;
          
        
        }
        .sotien{
          width: 23vw;
          text-align: center;
          line-height: 8vw;
          overflow:auto;
        
        }
        }
        }
        .datHangThanhCong-conatiner{
          margin: 16vw auto 5vw;
         
          .datHangTC{
            font-weight: 600;
          }
        }
        .chonNoiNhan-container{
          margin: 3vw 2vw;
          .chonNoiNhan{
            text-align: left;
            line-height: 6vw;
            font-weight: 600;
            color: #04aa6d;
    
          }
          .noiNhan-soBan{
            display: flex;
            gap: 2vw;
            font-size: 3vw;
            .noiNhan{
              width: 30vw;
              height: 8vw;
              border-radius: 4vw;
              
          border: solid 1px gray;
          text-align-last:center;
            }
            .soBan{
              width: 30vw;
              height: 8vw;
              border-radius: 4vw;
              border: solid 1px gray;
              text-align-last:center;
    
            }
            .maBaoVe{
              width: 30vw;
              height: 8vw;
              border-radius: 4vw;
              text-align:center;
              border: solid 1px gray;
    
            }
      
            
    
    
          }
        }
        .thongTinNguoiNhan-container{
          margin: 5vw 2vw;
          .thongTinNguoiNhan{
            text-align: left;
            font-weight: 600;
            line-height: 6vw;
            color: #04aa6d;
    
          }
          .thongTinChiTiet{
            margin-bottom: 2vw;
            .tieuDe{
              font-weight: 600;
             text-align: left;
             line-height: 6vw;
            }
            .noiDung{
             height: 8vw;
              width: 96vw;
              border: solid 1px gray;
              text-align: left;
              padding-left: 2vw;
              border-radius: 4vw;
             
            }
          }
          .diaChi{
            
          }
        }
        .datHang{
          background-color: red;
          width: 96vw;
          line-height: 8vw;
          border-radius: 4vw;
          font-weight: 600;
          color: #fff;
          margin: 10vw 2vw;
        }
        .datHang2{
          background-color: #e5e5e5;
          width: 96vw;
          line-height: 8vw;
          border-radius: 4vw;
          margin: 10vw 2vw;
          font-weight: 600;
        
        }
        .thongTinDonHang-container{
          margin: 0vw 2vw;
          .thongTinDonHang{
            text-align: left;
            font-weight: 600;
            line-height: 6vw;
            color: #04aa6d;
    
          }
          .soBan-maBaoMat{
            display: flex;
            font-weight: 600;
            .soBan{
              width: 48vw;
              text-align: left;
              line-height: 6vw;
    
            }
            .maBaoMat{
              width: 48vw;
              text-align: right;
              line-height: 6vw;
    
            }
          }
          .sanPham{
            margin-bottom: 1vw;
            .tenSanPham{
              width: 96vw;
              overflow: auto;
              text-align: left;
              font-weight: 600;
              color: red;
              line-height: 5vw;
          
            }
            .dacDiem-themGioHang{
              display: flex;
              margin-bottom: 1vw;
              .tenDD-gioPhut{
                width: 22vw;
              .tenDD{
                text-align: left;
                font-weight: 600;
                line-height: 6vw;
                overflow: auto;
                height: 6vw;
              }
              .gioPhut{
                text-align: left;
                line-height: 6vw;
                color: red;
              }
            }
          .giaSanPham{
            width: 22vw;
            .giaNY{
              text-decoration: line-through;
              line-height: 6vw;
              overflow: auto;
              text-align: left;
            }
            .giaKM{
              font-weight: 600;
              color: red;
              line-height: 6vw;
              overflow: auto;
              text-align: left;
            }
           
          }
          .soLuong-SL{
            width: 25vw;
            .soLuong{
              line-height: 6vw;
            }
            .thayDoiSl{
              display: flex;
              .giamSl{
                width: 4.5vw;
                color: #04aa6d;
                font-size: 3.9vw;
                font-weight: 600;
                line-height: 6vw;
                margin: auto 0.5vw;
           
    
              }
              .SL{
                width: 14vw;
                height: 6vw;
                text-align: center;
                font-weight: 600;
                border-radius: 3vw;
                border: solid 1px gray;
                color: red;
                overflow: auto;
      
              }
              input::placeholder {
                font-weight: 600;
                color: red;
                overflow: auto;
              }
              .tangSl{
         
              }
            }
            .SLMua{
              font-weight: 600;
              color: red;
              line-height: 6vw;
              overflow: auto;
            }
    
          }
          .thanhTien-TT{
            width: 22vw;
            .thanhTien{
              text-align: center;
              line-height: 6vw;
            }
            .TT{
              text-align: center;
              font-weight: 600;
              line-height: 6vw;
              overflow: auto;
              color: red;
            }
          }
          .daXong{
            width: 6vw;
            font-size: 4vw;
            line-height: 6vw;
            margin: 3vw auto;
            background-color: #04aa6d;
            color: #fff;
            font-weight: 600;
            border-radius: 1vw;
          }
          .chuaXong{
            width: 6vw;
            font-size: 4vw;
            line-height: 6vw;
            margin: 3vw auto;
            background-color: #e5e5e5;
            border-radius: 1vw;
            font-weight: 600;
      
          }
          
        }
          }
          .tinhTien{
            .tinhTien2{
              text-align: left;
              line-height: 6vw;
              font-weight: 600;
              margin-top: 2vw;
              color: #04aa6d;
            }
            .tongTien{
              display: flex;
            
              line-height: 6vw;
            .tieude{
              width: 62vw;
              line-height: 6vw;
              text-align: left;
            }
            .sotien{
              width: 34vw;
              text-align: center;
              line-height: 6vw;
              height: 6vw;
              border-radius: 3vw;

            }
            }
    
          }
          
        }
        .thanhToanQrCode-container{
          margin: 2vw;
          .thanhToanQr{
              text-align: left;
            
              font-weight: 600;
              margin: 2vw auto;
              color: #04aa6d;
          }
          .qr{
            width: 96vw;
            object-fit: cover;

          } 
        }
        .daThanhToan{
          text-align: center;
          margin: 10vw 2vw;
          font-weight: 600;
          width: 96vw;
          line-height: 8vw;
          border-radius: 4vw;
          background-color: red;
          color: #fff;
          text-transform: uppercase;
    
        }
       }
     }
     .pc{
       display: none;
     }
   }
   
 }
  @media screen and (min-width : 1024px) {
   .view{
      .mobile{
        display: none;
      }
      .pc{
        .GioHang-ConTaiNer{
      }
    }
    
  }
  }
  

@media screen and (max-width : 1023px) {
   .Shop2-Container{
    .sanPham-shop{
        // margin: 5vw auto;
        // justify-content: center;
        // align-items: center;
        background-color: #04aa6d;
        // .nhomSanPham-themSanPham{
        //     display: flex;
        //     text-align: center;
        //     font-size: 3.9vw;
        //     font-weight: 600;
          
        //     color: #fff;
         
    
        //     .suaMenu{
        //         line-height: 9vw;
        //         width: 9vw;
        //         margin-right: 1vw;
        //         // background-color: red; 
        //         border-radius: 1vw;

        //     }
        //     .nhomSanPham{
        //         height: 9vw; 
        //         text-transform: uppercase;
        //         width: 80vw; 
        //         text-align-last:center ;
        //         // background-color: red;
        //         // color: #fff;
        //         // border: solid 1px red;
        //         margin-right: 1vw; 
        //         border-radius: 1vw;
        //         font-weight: 600;
        //         option{
        //             text-transform: uppercase;
        //             width: 80vw; 
        //         }
        //     }
        //     .themSanPham{
        //         line-height: 9vw;
        //         width: 9vw; 
        //         // background-color: red;
        //         border-radius: 1vw;

        //     }
        //     .sapXep{
        //       font-size: 5vw;
        //       width: 9vw;
        //       line-height: 9vw;
        //       text-align: center;
        //       // background-color: red;
        //       color: #fff;
        //       font-weight: 600; 
        //       border-radius: 1vw;
        //       margin-right: 1vw;
        //     }
        //     .nhomSanPham2{
        //         height: 9vw; 
        //         text-transform: uppercase;
        //         width: 91vw; 
        //         text-align-last:center ;
        //         background-color: red;
        //         color: #fff;
        //         font-weight: 600;
        //         border: solid 1px red;
        //         border-radius: 1vw;
        //         option{
        //             text-transform: uppercase;
        //         }
        //     }
        // }
        .kiHieu-nhomSanPham2-menu{
          display: flex;
          // .kiHieu{
          //   width: 8vw;
          //   line-height: 8vw;
          //   border-radius: 50%;
          //   background-color: #fff;
          //   color: red;
          //   margin:1vw 1vw 1vw 2vw;
          //   font-weight: 600;
          //   font-size: 5vw;


          // }
          .nhomSanPham2{
            width: 90vw;
            color: #fff;
            line-height: 10vw;
            font-size: 3.9vw;
            font-weight: 800;
            text-align: left;
            text-transform: uppercase;
            margin-left: 2vw;
          }  
          .menu{
            width: 8vw;
            line-height: 8vw;
            color: #fff;
            margin:1vw 1vw 1vw 2vw;
            font-weight: 600;
            font-size: 5vw;

          }
      }
        .xacNhan-xoa{
            font-size: 3.5vw;
            justify-content: center;
            align-items: center;
            margin: 5vw auto;
            padding: 2vw 0vw;
            // border: solid 1px gray;
            width: 60vw;
            background-color: #fff;
            border-radius: 1vw;
            // color: #fff;
            a{
                text-decoration: none;
            }
            .xacNhan{
              
            }
            .huyBo-xoa{
              display: flex;
              gap: 10vw;
              margin: 2vw 5vw 0vw;
            //   border: solid 1px gray;
            //   width: 100vw;
              .huyBo{
               color: #fff;
                text-align: center;
                font-size: 2.5vw;
                font-weight: 500;
                width: 20vw;
                line-height: 6vw;
                border-radius: 1vw;
                background-color: #04aa6d;
                // border: solid 1px gray;
                // margin: 2vw 5vw;
              }
              .xoa{
                color: #fff;
                text-align: center;
                font-size: 2.5vw;
                font-weight: 500;
                width: 20vw;
                line-height: 6vw;
                border-radius: 1vw;
                background-color: red;
                
              }
            }
            
          }
          .trong{
            font-size: 3.5vw;
            font-weight: 500;
            margin: 5vw auto 100vw;
            .line{
                height: 3px;
                width: 100%;
                background: #e8e8e8;
                margin-top: 10vw;
                
                .inner{
                  width: 100%;
                  height: inherit;
                  background: #04aa6d;
                  animation: slide 2s ease-in-out infinite;
                }
              }
        }
       
       
        .sanPham-container{
            display: grid;
            grid-template-columns: 1fr 1fr;
            margin: auto 2vw;
            gap:2vw;
            padding-bottom: 30vw;
            border: none;
            .sanPham {
              width: 47vw;
                background-color: #fff;
                .anhSanPham {
                    width: 47vw;
                    height: 62vw;
                    object-fit: cover;
                    background-color: #fff;
                    cursor: pointer;
                }
         
                  .tenSP{
                  width: 46vw;
                  text-align: left;
                  font-size: 3.5vw;
                  font-weight: 600;
                  // text-transform: uppercase;
                  margin: auto 1vw;
                  line-height: 5vw;

                  }
                  .giaCu{
                    width: 47vw;
                    font-size: 3.5vw;
                    font-weight: 600;
                    text-align: left;
                    margin-left: 1vw;
                    line-height: 6vw;
                    text-decoration: line-through;
                  }
                  .giaBan-phanTram{
                  width: 47vw;
                  display: flex;
                  font-size: 3.9vw;
                  font-weight: 600;
                  .giaBan{
                    width: 30vw;
                    text-align: left;
                    margin-left: 1vw;
                    line-height: 6vw;
                    color: red;

                  }
                  .phanTram{
                    width: 15vw;
                    text-align: right;
                    line-height: 6vw;
                    margin-right: 1vw;
                    overflow: hidden


                  }
                  }
                  .themGH{
                    line-height: 8vw;
                    width: 41vw;
                    margin: 3vw;
                    font-size: 3.5vw;
                    font-weight: 600;
                    background-color: #e5e5e5;
                    border-radius: 4vw;
                    text-transform: uppercase;
                  } 
                  .daThem{
                    line-height: 8vw;
                    width: 41vw;
                    margin: 3vw;
                    color: #fff;
                    font-size: 3.5vw;
                    font-weight: 600;
                    background-color: red;
                    border-radius: 4vw;
                    text-transform: uppercase;
                  }
             
                // .tenSanPham {
                //     width: 47vw;
                //     font-size: 3vw;
                //     font-weight: 600;
                //     line-height: 6vw;
                //     text-transform: uppercase;
                //     word-wrap:break-word;
                //     overflow:auto;
                // }
                // .giaBan {
                //     width: 47vw;
                //     .giaBanMoi {
                //         color: red;
                //         font-size: 5vw;
                //         font-weight: 600;
                //         width: 47vw;
                //         line-height: 8vw;
                 
                //     }
                    
                //     .giaGiam{
                //             display: flex;
                //             gap: 2vw;

                //     .giabanCu {
                //       text-decoration: line-through;
                //                 font-size: 3.5vw;
                //                 font-weight: 300;
                //                 width:22.5vw ;
                //                 line-height: 6vw;
                //                 text-align: right;
                
                //             }
                //             .phanTram{
                //                 font-size: 3.5vw;
                //                 font-weight: 300;
                //                 width:22.5vw ;
                //                 line-height: 6vw;
                //                 text-align: left;
                //             }
                        
                //     }
                    
                   
                    
                // }
                // .muaHang {
                //     font-size: 3.9vw;
                //     font-weight: 600;
                //     background-color: #e5e5e5;
                //     width: 47vw;
                //     line-height: 8vw;
                // }
                // .daThem{
                //   color: #fff;
                //     font-size: 3.9vw;
                //     font-weight: 600;
                //     background-color: red;
                //     width: 47vw;
                //     line-height: 8vw;
                // }
                // .xemChiTiet{
                //     color: #04aa6d;
                //    font-size: 3vw;
                //    font-weight: 500;
                //    cursor: pointer;
                // }
                // .viTriSanPham{
                //     display: flex;
                //     font-size: 2vw;
                //     gap: 1vw;
                //     align-items: center;
                //     justify-content: center;
                //     line-height: 5vw;
                // }
               
              
            }
                    
        
            }
           
    }
    
    // .menuGioHang2{
    //     .gioHang2{
    //         object-fit: cover;
    //         width: 15vw;
    //         height: 15vw;
    //         font-size: 9vw;
    //         border-radius: 50%;
    //         border: solid 2px #04aa6d;
    //         background-color: #fff;
    //         position: fixed;
    //         cursor: pointer;
    //         left: 20px;
    //         bottom: 20px;
    //         z-index: 2;

    //     }
        
    // }
    // .gioHang-container{
    //   .gioHang{
    //       object-fit: cover;
    //       width: 15vw;
    //       height: 15vw;
    //       font-size: 9vw;
    //       border-radius: 50%;
    //       border: solid 2px #04aa6d;
    //       background-color: #fff;
    //       position: fixed;
    //       cursor: pointer;
    //       left: 20px;
    //       bottom: 20px;
    //       z-index: 2;
  
    //   }
    //   .soLuong-thanhTien{
    //       width: 16vw;
    //       font-size: 2.8vw;
    //       padding-left:1vw ;
    //       text-align: left;
         
    //           .soLuong{
    //               line-height: 4vw;
    //           font-weight: 500;
    //                color: red;
  
    //           }
    //           .thanhTien{
    //               line-height: 5vw;
    //           }
    //       }
    //  }
    .GioHang-container{
      position: fixed;
      left: 20px;
      bottom: 20px;
      z-index: 2;
      .gioHang-soLuong-thanhTien{
        display: flex;
        .gioHang{
          object-fit: cover;
          font-size: 8vw;
        // font-weight: 300;
        line-height: 16vw;
          width: 16vw;
          height: 16vw;;
          border-radius: 50%;
          border: solid 1px red;
          color: red;
          background-color: #fff;
        }
        .soLuong-thanhTien{
          font-size: 3.9vw;
          font-weight: 600;
          line-height: 8vw;
          text-align: left;
        .soLuong{
        
        }
        .thanhTien{
        color: red;
        }
      }
      }
    }
   }

   
}
@media screen and (min-width : 1024px) {
   
}

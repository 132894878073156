@media screen and (max-width : 1023px) {
 .view{
    .mobile{
      .TrangChuShop-ConTaiNer{
        margin-bottom: 50vw;
        .trong-container{
          .trong1{
            background-color: #fff;
            margin-top: 10vw;
            .line{
                height: 1vw;
                width: 100%;
                .inner{
                  width: 100%;
                  height: inherit;
                  background-color: #fff;
                  animation: slide 2s ease-in-out infinite;
                }
              }
          }
        
        }
        .GioHang-container{
          position: fixed;
          left: 20px;
          bottom: 20px;
          z-index: 2;
          .gioHang-soLuong-thanhTien{
            display: flex;
            .gioHang{
              object-fit: cover;
              font-size: 6.8vw;
            // font-weight: 300;
            line-height: 12vw;
              width: 12vw;
              height: 12vw;;
              border-radius: 50%;
              border: solid 1px red;
              color: red;
              background-color: #fff;
            }
            .soLuong-thanhTien{
              font-size: 3.5vw;
              font-weight: 600;
              line-height: 6vw;
              text-align: left;
            .soLuong{
            
            }
            .thanhTien{
            color: red;
            }
          }
          }
        }
    
      }
    }
    .pc{
      display: none;
    }
  }
  
}
 @media screen and (min-width : 1024px) {
  .view{
     .mobile{
       display: none;
     }
     .pc{
       .TrangChuShop-ConTaiNer{
     }
   }
   
 }
 }
 @keyframes slide{
  0%{
    transform-origin: left;
    transform: scalex(0.3);
  }
  25%{
    transform-origin: left;
    transform: scalex(1);
  }
  26%{
    transform-origin: right;
    transform: scalex(1);
  }
  50%{
    transform-origin: right;
    transform: scalex(0.3);
  }
  75%{
    transform-origin: right;
    transform: scalex(1);
  }
  76%{
    transform-origin: left;
    transform: scalex(1);
  }
  100%{
    transform-origin: left;
    transform: scalex(0.3);
  }
}


/* Mobile & Tablet */
@media screen and (max-width : 1023px) {
  .BoxSoanThao-ConTaiNer{
    text-align: left;
   

  }
}

/* PC*/
@media screen and (min-width : 1024px) {
    .BoxSoanThao-ConTaiNer{
        
    }
}
  @media screen and (max-width : 1023px) {
    .view{
       .mobile{
        .TrangCaNhan-ConTaiNer{
          margin-bottom: 30vw;
          font-size: 3.5vw;
          a{
            text-decoration: none;
        }
          .TrangCaNhan{
            
            .quayLai-tieuDe{
              display: flex;
              
              background-color: #fff;
              border-bottom: #d0d4dd 1px solid;
              position: fixed;
              top: 0px;
                z-index: 1;
              
              .quayLai{
                  color: #04aa6d;
                  width: 25vw;
                  text-align: left;
                  line-height: 9vw;
                  padding-left: 1vw;
            
              }
              .fabysa{
                color: #04aa6d;
                font-size: 6vw;
                font-weight: 600;
                width: 25vw;
                text-align: left;
                line-height: 9vw;
                padding-left: 2vw;
                
             
        
              }
              .tieuDe{
                font-size: 3.9vw;
                  font-weight: 600;
                  color: red;
                  text-align: center;
                  width: 50vw;
                  line-height: 9vw;
                  text-transform: uppercase;
              }
              .taiKhoan{
                text-align: right;
                width: 21vw;
                font-size: 2.8vw;
                line-height: 9vw;
                color: red;
                
              
        
              }
              .avatar{
           
                width: 9vw;
                height: 9vw;
                border-radius: 50%;
                border: solid 1vw #fff;
              }
              .dangXuat{
                width: 23vw;
                text-align: center;
                line-height: 7vw;
                 margin: 1vw;
                border: solid 1px red;
                border-radius: 2vw;
                text-decoration: none;
                color: red;
                // background-color: #d0d4dd;
                font-weight: 500;
                
              }
              .dangNhap{
                width: 23vw;
                text-align: center;
                line-height: 7vw;
                font-weight: 500;
                margin: 1vw;
                border: solid 1px #04aa6d;
                border-radius: 2vw;
                text-decoration: none;
                color: #04aa6d;
                // background-color: #04aa6d;
                
              }
              }
              .ttCaNhan-container{
            
                margin: 12vw 2vw auto;
                
              .tieuDe{
                line-height: 6vw;
                font-weight: 600;
                
                text-align: left;
              
              }
              .thongTin{
                display: flex;
                .hoTen-sdt{
                  width: 48vw;
                  .hoTen{
                    
                    
                    text-align: left;
                    line-height: 8vw;
                  }
                }
                .avatar-taiKhoan{
                  width: 48vw;
                  .taiKhoan{
                    
                    font-weight: 500;
                    text-align: center;
                    line-height: 8vw;
                    color: red;
                    display: block;
                  }
                  .tien-container{
                    
                    line-height: 9vw;
                  
                  }
                  
                }
              }
              .diaChi{
                
                text-align: left;
                line-height: 8vw;
              
              }
              }
              .tkNganHang-container{
                margin: auto 2vw;
                  .tieuDe{
                    line-height: 6vw;
                    font-weight: 600;
                    
                    text-align: left;
                  margin-top:  5vw;
                  
                  }
              .taikhoan{
                display: flex;
                .ttTaiKhoan{
                  width: 50vw;
            
                  .nganHang{
                    
                    text-align: left;
                    line-height: 8vw;
              
                  }
                  .tenNganHang{
                    
                    text-align: center;
                    line-height: 8vw;
                    width: 48vw;
                    border-radius: 4vw;
                    border: solid 1px gray;
                    margin-bottom: 2vw;
                    text-transform: uppercase;
                  
                }
                .soTien{
                  text-align: center;
                  width: 48vw;
                  border: solid 1px gray;
                  border-radius: 4vw;
                  line-height: 8vw;
        
                }
            
                }
                .qr-container{
                  width: 50vw;
                  margin-top: 8vw;
                  .maQr{
                    width: 48vw;
                    object-fit: cover;
                  }
                }
              }
            
            
              }
              .qlCaNhan-container{
                margin: 5vw 2vw;
                .tieuDe-iCon{
                  display: flex;
                  .tieuDe{
                    width: 80vw;
                    text-align: left;
                    line-height: 6vw;
                    font-weight: 600;

                  }
                  .iCon{
                    width: 16vw;
                    text-align: right;
                    line-height: 6vw;
                    font-weight: 600;

                  }
                }
                .noiDung{
                  display: block;
                  font-weight: 600;
                  color: #04aa6d;
                  text-align: left;
                  line-height: 8vw;
                  border-bottom: solid 1px #d0d4dd ;
                }
                .allShoplike{
                  margin-bottom: 2vw;
                  border-bottom: solid 1px #d0d4dd ;
                  text-decoration: none;
                  display: block;
                  color: #04aa6d;
                  .tenShop{
                    font-weight: 600;
                    text-align: left;
                    line-height: 8vw;
                    text-transform: uppercase;
                  }
                  .diaChi{
                    text-align: left;
                    line-height: 5vw;
                    color: black;
                  }
              }
                
              }
          }
        }
       }
       .pc{
         display: none;
       }
     }
     
   }
    @media screen and (min-width : 1024px) {
     .view{
        .mobile{
          display: none;
        }
        .pc{
          background-color: #e5e5e5;
          .TrangCaNhan-ConTaiNer{
            margin: 0vw 20vw;
            font-size: 1vw;
            border: solid 1px gray;
            padding-bottom: 10vw;
            background-color: #fff;
            a{text-decoration: none;
            }
            .TrangCaNhan{
              .quayLai-tieuDe{
                display: flex;
                background-color: #fff;
                border-bottom: #d0d4dd 1px solid;
                position: fixed;
                top: 0px;
                z-index: 1;
                .quayLai{
                    color: #04aa6d;
                    width: 10vw;
                    text-align: left;
                    line-height: 3vw;
                    padding-left: 1vw;
                    font-weight: 600;
              
                }
                .fabysa{
                  color: #04aa6d;
                  font-weight: 600;
                  width: 10vw;
                  text-align: left;
                  line-height: 3vw;
                  padding-left: 1vw;
                  
               
          
                }
                .tieuDe{
                  font-size: 1.5vw;
                    font-weight: 600;
                    color: red;
                    text-align: center;
                    width: 38.8vw;
                    line-height: 3vw;
                    text-transform: uppercase;
                }
                .dangXuat{
                  width: 9vw;
                  text-align: center;
                  line-height: 2vw;
                   margin: 0.5vw;
                  border: solid 1px red;
                  border-radius: 2vw;
                  text-decoration: none;
                  color: red;
                  font-weight: 500;
                  
                }
                .dangNhap{
                  width: 9vw;
                  text-align: center;
                  line-height: 2vw;
                  font-weight: 500;
                  margin: 0.5vw;
                  border: solid 1px #04aa6d;
                  border-radius: 2vw;
                  text-decoration: none;
                  color: #04aa6d;
                  
                }
            }
          .ttCaNhan-container{
         
            margin: 3.5vw 1vw auto;
          .tieuDe{
            line-height: 2vw;
            font-weight: 600;
            text-align: left;
          
          }
          .thongTin{
            display: flex;
            .hoTen-sdt{
              width: 30vw;
              .hoTen{
                text-align: left;
                line-height: 2vw;
              }
            }
            .avatar-taiKhoan{
              width: 28.8vw;
              .taiKhoan{
       
                font-weight: 500;
                text-align: center;
               line-height: 2vw;
                color: red;
                display: block;
              }
              .tien-container{
       
               line-height: 2vw;
                color: red;
                span{
                  font-weight: 600;
                  line-height: 2vw;
                  text-align: center;
                  color: #04aa6d;
                  text-decoration-line:overline;
                  text-decoration-color:red;
                  text-decoration-style:double;
                }
              }
              .quyDoi{
                font-size: 2.8vw;
               line-height: 2vw;
              }
              
            }
          }
          .diaChi{
   
            text-align: left;
            line-height: 2vw;
          
          }
          }
          .tkNganHang-container{
            margin: auto 1vw;
              .tieuDe{
               line-height: 2vw;
                font-weight: 600;
                text-align: left;
               margin-top:  1vw;
              
              }
          .taikhoan{
            display: flex;
            .ttTaiKhoan{
              width: 29vw;
        
              .stk{
       
                text-align: left;
                line-height: 2vw;
          
              }
              select, option,input{
       
                text-align-last: center;
                height: 2vw;
                width: 29vw;
                border-radius: 1vw;
                border: solid 1px gray;
                margin-bottom: 1vw;
                text-transform: uppercase;
              
            }
            .input2{
              color: red;
        font-weight: 600;
        border: solid 1px gray;
        border-radius: 1vw;
          line-height: 2vw;
    
            }
        
            }
            .qr-container{
              width: 27.8vw;
            
            }
          }
        
        
          }
          .qlCaNhan-container{
            margin: auto 1vw;
            .tieuDe{
             line-height: 2vw;
              font-weight: 600;
              text-align: left;
             margin: 1vw auto 0vw;
            
            }
            .noiDung{
              display: block;
               font-size: 1vw;
              font-weight: 600;
              color: #04aa6d;
              text-align: left;
             line-height: 2vw;
              border-bottom: solid 1px #d0d4dd ;
            }
            .noiDung2{
              display: block;
              font-size: 1vw;
              font-weight: 600;
              color: gray;
              text-align: left;
             line-height: 2vw;
              border-bottom: solid 1px #d0d4dd ;
            }
          }
          
          .timShiper-container{
            margin: auto 1vw;
            .tieuDe{
             line-height: 2vw;
              font-weight: 600;
     
              text-align: left;
             margin: 1vw auto 0vw;
            
            }
            .noiDung{
              display: block;
     
              font-weight: 600;
              color: #04aa6d;
              text-align: left;
             line-height: 2vw;
              border-bottom: solid 1px #d0d4dd ;
            }
          }
          .timShop-container{
           margin: auto 1vw;
            .tieuDe{
             line-height: 2vw;
              font-weight: 600;
     
              text-align: left;
             margin: 1vw auto 0vw;
            
            }
            .noiDung{
              display: block;
              margin-bottom: 1vw;
              font-weight: 600;
              color: #04aa6d;
              text-align: left;
             line-height: 2vw;
              border-bottom: solid 1px #d0d4dd ;
            }
            .timTheoTenSdt{
              margin: 1vw auto;
              .input-tim{
                display: flex;
                gap: 2vw;
              .input{
                width: 48.8vw;
                height: 2vw;
                border: solid 1px gray;
                border-radius: 1vw;
                text-align: left;
                padding-left: 1vw;
              }
              .tim{
                width: 10vw;
                font-weight: 600;
                color: #fff;
                line-height: 2vw;
                background-color: #04aa6d;
                border-radius: 1vw;
              
              }
              }
            }
            .toaDo{
              display: flex;
              border-bottom: solid 1px #d0d4dd ;
     
              font-weight: 600;
              .noiDungx{
                width: 26vw;
                color: #04aa6d;
                text-align: left;
                line-height: 8vw;
              }
        .lat{
          width: 35vw;
          line-height: 8vw;
        }
        .lont{
          width: 35vw;
          line-height: 8vw;
        }
            }
            .diaChi2{
              margin: 1vw auto;
            }
            .allShoplike{
              margin-bottom: 1vw;
              border-bottom: solid 1px #d0d4dd ;
              text-decoration: none;
              display: block;
     
              color: #04aa6d;
              .tenShop{
        font-weight: 600;
        text-align: left;
       line-height: 2vw;
        text-transform: uppercase;
              }
              .diaChi{
                  text-align: left;
                 line-height: 2vw;
                  color: black;
              }
          }
          }
            }
          }
      }
      
    }
    }
    
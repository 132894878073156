/* Mobile & Tablet */
@media screen and (max-width : 1023px) {
   
   .chiTietSanPham-container{
    .quayLai-tieuDe{
      display: flex;
      font-size: 3.5vw;
      background-color: #fff;
      border-bottom: #d0d4dd 1px solid;
      position: fixed;
  
      top: 0px;
        z-index: 1;
      a{
          text-decoration: none;
      }
      .quayLai{
          color: #04aa6d;
          width: 25vw;
          text-align: left;
          line-height: 9vw;
          padding-left: 2vw;
         
      }
      .tieuDe{
        font-size: 3.9vw;
          font-weight: 600;
          color: red;
          text-align: center;
          width: 75vw;
          line-height: 9vw;
          text-transform: uppercase;
          padding-right: 25vw;

      }
  }
  .sanPham-container{
    margin: 12vw auto 30vw;
   
    .tenSP-themGioHang{
      display: flex;
      margin:3vw 2vw;
    
      .tenSP{
        width: 66vw;
        height: 6vw;
        text-align: left;
        line-height: 6vw;
        font-size: 3.5vw;
        font-weight: 600;
        overflow: auto;
        text-decoration: none;
        padding-left: 1vw;
        // color: black;
      
      }
      .themGioHang{
        width: 30vw;
        text-align: center;
        background-color: red;
        color: #fff;
        font-size: 3vw;
        font-weight: 600;
        line-height: 6vw;
        border-radius: 3vw;
        text-transform: uppercase;
      }
      .dathemGioHang{
        width: 30vw;
        text-align: center;
        background-color: #e5e5e5;
        font-size: 3vw;
        font-weight: 600;
        line-height: 6vw;
        border-radius: 3vw;
        text-transform: uppercase;
      }
      
    }
    .bangGia{
      padding:  2vw  0vw;
      display: grid;
      grid-template-columns: 1fr;
      gap: 2vw;
      background-color: #e5e5e5;
      .gia1{
        background-color: #fff;
        display: flex;
        .anhSp{
          width: 16vw;
          height: 20vw;
          object-fit: cover;

        }
        .giaKm-tenSp{
          width: 28vw;
          padding-top: 4vw;
          font-size: 3.5vw;
          .giaKm{
            line-height: 6vw;
            color: red;
          

          }
          .tenSp{
            line-height: 6vw;
       
          
          }
        }
        .giaNY-giam{
          width: 28vw;
          padding-top: 4vw;
          font-size: 3.5vw;
          .giaNY{
            line-height: 6vw;
       
            text-decoration: line-through;
          }
          .giam{
            line-height: 6vw;
        
          }

        }
        .tinhTrang-sl{
          width: 28vw;
          padding-top: 4vw;
          font-size: 3.5vw;
          .tinhTrang{
            line-height: 6vw;
         
          }
          .sl{
            line-height: 6vw;
         
          }
        }
      }
    }
    .thongTinSp{
      margin-top: 2vw;
      .tieuDeTT{
        text-align: left;
        padding-left: 2vw;
        line-height: 5vw;
        font-size: 3.5vw;
        font-weight: 600;


      }
      .noiDungTT{
        margin:  auto 2vw;
        text-align: justify;
        font-size: 3.5vw;
        line-height: 5vw;
        img, svg{
          width: 96vw;
          object-fit: cover;
          
        }
      

      }
    }
  }
   }
    }
    @media screen and (min-width : 1024px) {
    
    }


/* Mobile & Tablet */
@media screen and (max-width : 1023px) {
  .view{
    .mobile{
      .BaoCao-ConTaiNer{
    
      }
    }
    .pc{
      display: none;
    }
  }
  
}

/* PC*/
@media screen and (min-width : 1024px) {
  .view{
    .mobile{
     display: none;
    }
    .pc{
      .BaoCao-ConTaiNer{
    
      }
    }
  }
}
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 3vw;
    font: inherit;
    vertical-align: baseline;
    text-decoration: none;
   font-family: "Noto Serif", serif;

}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
    font-family: "Noto Serif", serif;

}
body {
    line-height: 1;
    font-family: "Noto Serif", serif;

}
ol,
ul {
    list-style: none;
    font-family: "Noto Serif", serif;

}
blockquote,
q {
    quotes: none;
    font-family: "Noto Serif", serif;

}
blockquote:before,
blockquote:after,
q:before,
q:after {
    content: "";
    content: none;
    font-family: "Noto Serif", serif;

}
table {
    border-collapse: collapse;
    border-spacing: 0;
    font-family: "Noto Serif", serif;

}

html {
    font-size: 3vw;
    font-family: "Noto Serif", serif;

}
button {
    cursor: pointer;
    border: none;
    align-self: center;
    margin-top: 1rem;
    font-size: 1rem;
    padding: 0.65rem;
    border-radius: 4px;
    color: white;
    background-color: rgb(161, 161, 161);
    font-family: "Noto Serif", serif;

}
.App{
    max-width: calc(100% - 0px);
    margin: 0 auto;
    text-align: center;
    justify-content: center;
    align-items: center;
   font-family: "Noto Serif", serif;

   
}
@media screen and (max-width : 1023px) {
 .view{
    .mobile{
      .DonHangShop-ConTaiNer{
        a{text-decoration: none};
        font-size: 3.5vw;
        .quayLai-tieuDe{
          width: 100vw;
          display: flex;
          font-size: 3.5vw;
          background-color: #fff;
          border-bottom: #d0d4dd 1px solid;
          position: fixed;

          top: 0px;
            z-index: 1;
          a{
              text-decoration: none;
          }
          .quayLai{
              color: #04aa6d;
              width: 35vw;
              text-align: left;
              line-height: 9vw;
              padding-left: 2vw;
            
          }
            .donHang{
                font-size: 3.9vw;
                font-weight: 600;
                color: red;
                text-transform: uppercase;
                width: 30vw;
                line-height: 9vw;
        

            }
            .taiKhoanShop{
              width: 35vw;
              text-align: right;
              padding-right: 2vw;
              line-height: 9vw;
              color: black;
        
          }
        }
        .nhomDonHang{
          padding-top: 11vw;
          .noiNhan{
            display: flex;
            margin: auto 2vw;
            gap: 3vw;
            .nhanTaiBan{
              width: 30vw;
              height: 8vw;
              border-radius: 4vw;
              line-height: 8vw;
              color: #fff;
              background-color: #04aa6d;
            }
            .nhanTaiBan2{
              width: 30vw;
              height: 8vw;
              border-radius: 4vw;
              line-height: 8vw;
              border: solid 1px gray;
            }
          }
          .trangThai{
            padding-top: 2vw;
            .chonTrangThai{
              background-color: #fff;
              text-align: left;
              padding-left: 2vw;
              border: solid 1px gray;
              width: 96vw;
              text-align: left;
              height: 8vw;
              line-height: 8vw;
              border-radius: 4vw;
            }
          }
        }
        .tieuDe-traHang{
          display: flex;
          margin-top: 3vw;
          font-weight: 600;
          .tieuDe{
            text-align: left;
            padding-left: 2vw;
            line-height: 8vw;
            width: 70vw;
      
          }
          .traHang{
            line-height: 8vw;
            text-align: right;
            width: 30vw;
            padding-right: 2vw;
            color: #04aa6d;
          }
        }
        
        .allDonHang{
          padding-top: 2vw;
          background-color: #e5e5e5;
          margin-bottom: 30vw;
  

          .donHang{
            background-color: #fff;
            border-bottom: solid 2vw #e5e5e5;
            .thoiGian-idDonHang{
              display: flex;
              .thoiGian{
                width: 30vw;
                padding-left: 2vw;
                text-align: left;
                line-height: 5vw;
              }
              .idDonHang{
                width: 70vw;
                padding-right: 2vw;
                text-align: right;
                line-height: 5vw;
            

              }
            }
            .noiNhan-tinhTrang{
              display: flex;
              .noiNhan{
                width: 30vw;
                padding-left: 2vw;
                text-align: left;
                line-height: 5vw;
                color: #04aa6d;

              }
              .tinhTrang{
                width: 70vw;
                padding-right: 2vw;
                text-align: right;
                line-height: 5vw;
                color: red;

              }
            }

          }
        }
      }
    }
    .pc{
      display: none;
    }
  }
  
}
 @media screen and (min-width : 1024px) {
  .view{
     .mobile{
       display: none;
     }
     .pc{
       .DonHangShop-ConTaiNer{
     }
   }
   
 }
 }
 
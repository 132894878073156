@media screen and (max-width : 1023px) {
 .view{
  .DonViHanhChinhVN-ConTaiNer{
    font-size: 3.5vw;
    margin-bottom: 30vw;
    .quayLai-tieuDe{
      display: flex;
      font-size: 3.5vw;
      background-color: #fff;
      border-bottom: #d0d4dd 1px solid;
      position: fixed;
  
      top: 0px;
        z-index: 1;
      a{
          text-decoration: none;
      }
      .quayLai{
          color: #04aa6d;
          width: 25vw;
          text-align: left;
          line-height: 9vw;
                      padding-left: 2vw;
         
      }
      .tieuDe{
        font-size: 3.9vw;
          font-weight: 600;
          color: red;
          text-align: center;
          width: 75vw;
          line-height: 9vw;
          text-transform: uppercase;
          padding-right: 25vw;

      }
  }
  
    .donViHanhChinhVN{
      margin-top: 12vw;
      width: 100vw;
      padding-left: 2vw;
      text-align: left;
      .DVHCVN-themTinh{
        .DVHCVN-taoMoi{
          display: flex;
          .DVHCVN{
            width: 70vw;
              line-height: 5vw;

          }
          .taoMoi{
            width: 30vw;
            text-align: right;
            padding-right: 2vw;
            color: #04aa6d;
            line-height: 5vw;
            font-style:italic;
          }
        }
        
        .themTinh{
          padding-left: 5vw;
          color: #04aa6d;
          line-height: 5vw;
          font-style:italic;
        }
      }
      .dsTinh{
    
        text-align: left;
        padding-left: 5vw;
        .tenTinh-xoaTinh{
          display: flex;
          gap: 1vw;
          .tenTinh{
            line-height: 5vw;
          }
          
          .xoaTinh{
            width: 6vw;
            line-height: 5vw;
            border-radius: 3vw;
            color: red;
            text-align: center;
          }
        }
        .themHuyen{
          padding-left: 5vw;
          color: #04aa6d;
          line-height: 5vw;
          font-style:italic;

        }
        
        .dsHuyen{
          padding-left: 5vw;
          .tenHuyen-xoaHuyen{
            display: flex;
            gap: 1vw;
            .tenHuyen{
                line-height: 5vw;
            }
            .xoaHuyen{
              width: 6vw;
              line-height: 5vw;
              border-radius: 3vw;
              color: red;
              text-align: center;
            }
          }
          .themXa{
            padding-left: 5vw;
            color: #04aa6d;
            line-height: 5vw;
            font-style:italic;
          }
          
          .dsXa{
            padding-left: 5vw;
            .tenXa-xoaXa{
              display: flex;
              gap: 1vw;
              .tenXa{
                  line-height: 5vw;
              }
              .xoaXa{
                width: 6vw;
                  line-height: 5vw;
                border-radius: 3vw;
                color: red;
                text-align: center;
              }
            }
            .themThonXom{
              padding-left: 5vw;
              color: #04aa6d;
              line-height: 5vw;
              font-style:italic;
            }
            .dsThonXom{
              padding-left: 5vw;
              .tenThonXom-xoaThonXom{
                display: flex;
                gap: 1vw;
                .tenThonXom{
                    line-height:5vw;
                }
                .xoaThonXom{
                  width: 6vw;
                    line-height: 5vw;
                  border-radius: 3vw;
                  color: red;
                  text-align: center;
                }
              }
              
            }
          }

        }
      }
    }
    .boxThemDuLieu{
      font-size: 3.5vw;
    font-weight: 600;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    overflow: auto;
    background-color: rgba(0,
            0,
            0,
            0.5);
            justify-content: center;
            align-items: center;
      .themTinh-container{
        background-color: #fff;
        position: fixed;
        z-index: 9;
        width: 96vw;
        max-height: 60vh;
        overflow: auto;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 3vw;
        padding: 1vw 2vw 5vw;
        .themTinh-close{
          display: flex;
          .themTinh{
            width: 82vw;
            text-align: left;
            line-height: 8vw;

          }
          .close{
            width: 10vw;
            text-align: right;
            line-height: 8vw;
            font-size: 5vw;
            font-weight: 600;
            color: red;
          }
        }
        .input-ok{
          display: flex;
          margin-top: 3vw;
          input{
            width: 82vw;
            text-align: left;
            border-radius: 4vw;
            border: solid 1px gray;
            height: 8vw;
            padding-left: 5vw;
          }
          .ok{
            width: 10vw;
            text-align: right;
            line-height: 8vw;
            font-size: 5vw;
            font-weight: 600;
            color: #04aa6d;
          }
        }
      }
    }
  }
  }
  
}
 @media screen and (min-width : 1024px) {
  .view{
      background-color: #e5e5e5;
    .DonViHanhChinhVN-ConTaiNer{
      font-size: 1vw;
      margin: 0vw 20vw;
      padding-bottom: 30vw;
      border: solid 1px gray;
      background-color: #fff;
      .quayLai-tieuDe{
        display: flex;
        border-bottom: #d0d4dd 1px solid;
        background-color: #fff;
        width: 58.8vw;
        position: fixed;
        top: 0px;
        z-index: 1;
        .quayLai{
          padding-left: 1vw;
          color: #04aa6d;
          width: 10vw;
          text-align: left;
          line-height: 3vw;
          text-decoration: none;
          font-weight: 600;
           
        }
        .tieuDe{
          font-size: 1.5vw;
          font-weight: 600;
          color: red;
          text-align: center;
          width: 40vw;
          line-height: 3vw;
          text-transform: uppercase;
        }
    }
    
      .donViHanhChinhVN{
        margin-top: 3.5vw;
        padding-left: 1vw;
        text-align: left;
        .DVHCVN-themTinh{
          .DVHCVN-taoMoi{
            display: flex;
            .DVHCVN{
              width: 50vw;
                line-height: 1.5vw;
                text-transform: uppercase;
                font-weight: 600;

            }
            .taoMoi{
              width: 9vw;
              text-align: right;
              padding-right: 2vw;
              color: #04aa6d;
                line-height: 1.5vw;
              font-size: 0.8vw;
              font-style:italic;
            }
          }
          
          .themTinh{
                                 padding-left: 2vw;
            color: #04aa6d;
              line-height: 1.5vw;
            font-size: 0.8vw;
            font-style:italic;
          }
        }
        .dsTinh{
          text-align: left;
          padding-left: 2vw;
          .tenTinh-xoaTinh{
            display: flex;
            gap: 1vw;
            .tenTinh{
              line-height: 1.5vw;
            }
            .xoaTinh{
              line-height: 1.5vw;
              color: red;
              text-align: center;
            }
          }
          .themHuyen{
            padding-left: 2vw;
            color: #04aa6d;
            line-height: 1.5vw;
            font-size: 0.8vw;
            font-style:italic;

          }
          
          .dsHuyen{
            padding-left: 2vw;
            .tenHuyen-xoaHuyen{
              display: flex;
              gap: 1vw;
              .tenHuyen{
                line-height: 1.5vw;
              }
              .xoaHuyen{
                line-height: 1.5vw;
                border-radius: 3vw;
                color: red;
                text-align: center;
              }
            }
            .themXa{
              padding-left: 2vw;
              color: #04aa6d;
              line-height: 1.5vw;
              font-size: 0.8vw;
              font-style:italic;
            }
            
            .dsXa{
              padding-left: 2vw;
              .tenXa-xoaXa{
                display: flex;
                gap: 1vw;
                .tenXa{
                  line-height: 1.5vw;
                }
                .xoaXa{
                    line-height: 1.5vw;
                  border-radius: 3vw;
                  color: red;
                  text-align: center;
                }
              }
              .themThonXom{
                                     padding-left: 2vw;
                color: #04aa6d;
                  line-height: 1.5vw;
                font-size: 0.8vw;
                font-style:italic;
              }
              .dsThonXom{
                                     padding-left: 2vw;
                .tenThonXom-xoaThonXom{
                  display: flex;
                  gap: 1vw;
                  .tenThonXom{
                      line-height: 1.5vw;
                  }
                  .xoaThonXom{
                      line-height: 1.5vw;
                    border-radius: 3vw;
                    color: red;
                    text-align: center;
                  }
                }
                
              }
            }
  
          }
        }
      }
      .boxThemDuLieu{
        font-size: 2vw;
      font-weight: 600;
      position: fixed;
      z-index: 1;
      left: 0;
      top: 0;
      width: 100vw;
      height: 100vh;
      overflow: auto;
      background-color: rgba(0,
              0,
              0,
              0.5);
              justify-content: center;
              align-items: center;
        .themTinh-container{
          background-color: #fff;
          position: fixed;
          z-index: 9;
          width: 56vw;
          max-height: 60vh;
          overflow: auto;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          border-radius: 3vw;
          padding: 1vw 2vw 3vw;
          .themTinh-close{
            display: flex;
            .themTinh{
              width: 50vw;
              text-align: left;
              line-height: 3vw;

            }
            .close{
              width: 6vw;
              text-align: right;
              line-height: 3vw;
              font-size: 3vw;
              font-weight: 600;
              color: red;
            }
          }
          .input-ok{
            display: flex;
            margin-top: 1vw;
            input{
              width: 50vw;
              text-align: left;
              border-radius: 4vw;
              border: solid 1px gray;
              height: 3.5vw;
              padding-left: 2vw;
            }
            .ok{
              width: 6vw;
              text-align: right;
              line-height: 3.5vw;
              font-size: 3vw;
              font-weight: 600;
              color: #04aa6d;
            }
          }
        }
      }
    }
   
 }
 }
 
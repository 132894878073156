
  /* Mobile & Tablet */
@media screen and (max-width : 1023px) {
  .loading-container{
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    overflow: auto;
    background-color: #fff;
    justify-content: center;
    align-items: center;
    // border: solid 1px gray;
    .loading{
      position: fixed;
      z-index: 9;
      width: 100vw;
      height: 40vw;
      overflow: auto;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
      // border: solid 1px gray;

      .loader {
        border: 0.2vw solid #FFF;
        width: 30vw;
        height: 30vw;
        background: #04aa6d;
        border-radius: 50%;
        display: inline-block;
        position: relative;
        box-sizing: border-box;
        animation: rotation 2s linear infinite;
      }
      .loader::after {
        content: '';  
        box-sizing: border-box;
        position: absolute;
        left: 50%;
        top: 50%;
        border: 15vw solid;
        border-color: transparent #FFF;
        border-radius: 50%;
        transform: translate(-50%, -50%);
      }
      @keyframes rotation {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      } 
      .capNhatDuLieu{
        font-size: 5vw;
        font-weight: 600;
        line-height: 8vw;
      }
    }
  }

  

  

}

/* PC*/
@media screen and (min-width : 1024px) {
   
}
@media screen and (max-width : 1023px) {
 .view{
    .mobile{
      .GioiThieuShop-ConTaiNer{
          font-size: 3.5vw;
           .quayLai-tieuDe{
               display: flex;
               font-size: 3.5vw;
               background-color: #fff;
               border-bottom: #d0d4dd 1px solid;
               position: fixed;
           
               top: 0px;
                 z-index: 1;
               a{
                   text-decoration: none;
               }
               .quayLai{
                   color: #04aa6d;
                   width: 25vw;
                   text-align: left;
                   line-height: 9vw;
                   padding-left: 2vw;
                  
               }
               .tieuDe{
                 font-size: 3.9vw;
                   font-weight: 600;
                   color: red;
                   text-align: center;
                   width: 75vw;
                   line-height: 9vw;
                   text-transform: uppercase;
                   padding-right: 25vw;
         
               }
           }
           .lienHe{
            margin-top: 12vw;
            .tenShop{
              font-size: 3.9vw;
              font-weight: 600;
              text-transform: uppercase;
              line-height: 8vw;
              text-align: center;

            }
            .diaChi{
              margin: auto 2vw;
              text-align: left;
              line-height: 5vw;
              text-decoration: none;
      

            }
           }
           .nganhNghe{
            margin: 2vw 2vw 0vw;
            .tieuDe{
              font-weight: 600;
              text-align: left;
              line-height: 6vw;

            }
            .noiDung{
              text-align: left;
              line-height: 5vw;
            }
           }
           .khachHang{
            margin: 2vw;
            .tieuDe{
              font-weight: 600;
              text-align: left;
              line-height: 6vw;
            }
            .noiDung{
              text-align: left;
              line-height: 8vw;
              font-weight: 600;
              border-bottom: solid 1px #d0d4dd ;
              color: #04aa6d;
              text-decoration: none;
              display: block;
            }
           }  
            .qrcode-container{
               margin: 2vw auto 30vw;
               .qrcode{
                line-height: 6vw;
                font-weight: 600;
                text-align: left;
                margin: 0vw 2vw;

              }
               .taiqr{
            
                line-height: 8vw;
                font-weight: 500;
             
              }
               
              
               .link-copylink{
                // display: flex;
                border: solid 1px gray;
                margin: 5vw 2vw;
                min-height: 8vw;
                border-radius: 4vw;
                
                .copylink{
                  width: 16vw;
                  text-align: left;
                  padding-left: 2vw;
                  line-height: 8vw;
                  color:#04aa6d ;
                  font-weight: 600;
                }
                .daCopylink{
                  width: 16vw;
                  text-align: left;
                  padding-left: 2vw;
                  line-height: 8vw;
                  color: red;
                  font-weight: 600;
                }
                .link{
                  // width: 80vw;
                  text-align: left;
                  padding-left: 2vw;
                  overflow: auto;
                  line-height: 8vw;

                }
               }
            }
           
         
      }
    }
    .pc{
      display: none;
    }
  }
  
}
 @media screen and (min-width : 1024px) {
  .view{
     .mobile{
       display: none;
     }
     .pc{
       .GioiThieuShop-ConTaiNer{
     }
   }
   
 }
 }
 
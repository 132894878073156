@media screen and (max-width : 1023px) {
 .view{
    .mobile{
      .MenuShop-ConTaiNer{
        font-size: 3.5vw;
        font-weight: 600;
        position: fixed;
        z-index: 3;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        overflow: auto;
        background-color: rgba(0,
                0,
                0,
                0.5);
                justify-content: center;
                align-items: center;
                .tieuDe-noiDung{
                  background-color: #fff;
                  position: fixed;
                  z-index: 9;
                  width: 80vw;
                  max-height: 60vh;
                  overflow: auto;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  border-radius: 3vw;
                  padding-bottom: 5vw;
                  .tieuDe-close{
                    display: flex;
                    margin: 2vw;
                    .tieuDe{
                      width: 68vw;
                      text-align: left;
                      line-height: 6vw;
                      font-weight: 600;
                     

                    }
                    .close{
                      width: 8vw;
                      text-align: right;
                      line-height: 6vw;
                      font-weight: 600;
                      font-size: 5vw;
                    }
                  }
                
                  .daChon{
                    background-color: #04aa6d;
                    color: #fff;
                    margin: 2vw;
                    line-height: 8vw;
                    text-transform: uppercase;
                    text-align: left;
                    padding-left: 2vw;
              
                  }
                  .chuaChon{
                    background-color: #e5e5e5;
                    margin: 2vw;
                    text-align: left;
                    line-height: 8vw;
                    text-transform: uppercase;
                    padding-left: 2vw;
                  }
                }
   
      }
    }
    .pc{
      display: none;
    }
  }
  
}
 @media screen and (min-width : 1024px) {
  .view{
     .mobile{
       display: none;
     }
     .pc{
       .MenuShop-ConTaiNer{
     }
   }
   
 }
 }
 
.thayPassword-container{
    font-size: 3.5vw;
 
        a{
            text-decoration: none;
            color: #fff;
        }
        .quayLai-tieuDe2{
            display: flex;
            font-size: 3.5vw;
            background-color: #fff;
            border-bottom: #d0d4dd 1px solid;
            position: fixed;
            top: 0px;
              z-index: 1;
            a{
                text-decoration: none;
            }
            .quayLai{
                color: #04aa6d;
                width: 25vw;
                text-align: left;
                line-height: 9vw;   
                padding-left: 2vw;
            }
            .tieuDe{
              font-size: 3.9vw;
                font-weight: 600;
                color: red;
                text-align: center;
                width: 75vw;
                line-height: 9vw;
                text-transform: uppercase;
                padding-right: 25vw;
            }
        }
        .login-container{
        margin: 12vw 2vw 30vw;
        .baoLoi{
            color: red;
            line-height: 8vw;
        }
        .labelDangNhap{
            line-height: 6vw;
            font-weight: 600;
            text-align: left;
            margin: 5vw auto 0vw;
        }
        .inputDangNhap{
            width: 96vw;
            height: 10vw;
            text-align: left;
            padding-left: 5vw;
            border: solid 1px gray;
            border-radius: 5vw;
        

        }
        
        .buttonDangNhap{
            width: 96vw;
            background-color: #04aa6d;
            line-height: 10vw;
            font-weight: 600;
            color: #fff;
            border-radius: 5vw;
            margin: 10vw auto;

        }
        }
}
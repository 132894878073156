@media screen and (max-width : 1023px) {
    .view{
       .mobile{
         .QuanLyShop-ConTaiNer{
          font-size: 3.5vw;
          .quayLai-tieuDe{
              display: flex;
              font-size: 3.5vw;
              background-color: #fff;
              border-bottom: #d0d4dd 1px solid;
              position: fixed;
          
              top: 0px;
                z-index: 1;
              a{
                  text-decoration: none;
              }
              .quayLai{
                  color: #04aa6d;
                  width: 25vw;
                  text-align: left;
                  line-height: 9vw;
                  padding-left: 2vw;
                 
              }
              .tieuDe{
                font-size: 3.9vw;
                  font-weight: 600;
                  color: red;
                  text-align: center;
                  width: 75vw;
                  line-height: 9vw;
                  text-transform: uppercase;
                  padding-right: 25vw;
        
              }
          }
          .quanLyShop{
            margin: 12vw 2vw auto;
            .quanLy{
              text-align: left;
              line-height: 8vw;
              font-weight: 600;
              border-bottom: solid 1px #d0d4dd ;
              color: #04aa6d;
              text-decoration: none;
              display: block;
              span{
                width: 6vw;
                font-size: 3.9vw;
                line-height: 8vw;
                text-align: center;
                color: red;
                display: inline-block;
              }
              .fabysaXanh{
                font-size: 3.5vw;
                font-weight: 600;
                line-height: 8vw;
                text-align: center;
                color: #04aa6d;
                text-decoration-line:overline;
                text-decoration-color:red;
                text-decoration-style:double;
              }
            }
          }
          .gioiThieuFabysa{
           
            .logoDangNhap{
              margin-top: 12vw;
              text-align: center;
              img{
                  width: 50vw;
                  height: 50vw;
                  border-radius: 50%;
              }
            }
            .tieuDe{
              line-height: 8vw;
              color: red;
              font-weight: 600;

            }
            .noiDung{

            }
          }
         }
       }
       .pc{
         display: none;
       }
     }
     
   }
    @media screen and (min-width : 1024px) {
     .view{
        .mobile{
          display: none;
        }
        .pc{
          .QuanLyShop-ConTaiNer{
        }
      }
      
    }
    }
    